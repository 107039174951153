<template>
    <section class="fb__period" aria-label="조회조건">
		<div class="period">
			<header aria-label="조회조건 타이틀">
				<h2 class="period__title" v-if="title">
					<span>{{title}}</span>
					<span class="period__tip" v-if="title == '추가결제금액 조회'">*추가결제는 고객님의 핸드폰번호로 전달드리는 URL을 통해 진행해주세요.</span>
				</h2>
				<h2 class="fb__title--hidden" v-else>조회기간</h2>
			</header>
			<div class="period__container" aria-label="조회조건 리스트">
				<slot name="period-top"></slot>
				<div class="period__main">
					<div class="period__quick">
						<label>
							<input type="radio" name="period-quick" value="today" class="blind" :disabled="disabled" tabindex="0" autocomplete="off" v-model="filter.period">
							<span>오늘</span>
						</label>
						<label>
							<input type="radio" name="period-quick" value="6months" class="blind" :disabled="disabled" tabindex="0" autocomplete="off" v-model="filter.period">
							<span><em>6</em>개월</span>
						</label>
						<label>
							<input type="radio" name="period-quick" value="thisYear" class="blind" :disabled="disabled" tabindex="0" autocomplete="off" v-model="filter.period">
							<span><em>{{getPastYear(0)}}</em></span>
						</label>
						<label>
							<input type="radio" name="period-quick" value="1years" class="blind" :disabled="disabled" tabindex="0" autocomplete="off" v-model="filter.period">
							<span><em>{{getPastYear(1)}}</em></span>
						</label>
						<label>
							<input type="radio" name="period-quick" value="2years" class="blind" :disabled="disabled" tabindex="0" autocomplete="off" v-model="filter.period">
							<span><em>{{getPastYear(2)}}</em></span>
						</label>
						<label>
							<input type="radio" name="period-quick" value="3years" class="blind" :disabled="disabled" tabindex="0" autocomplete="off" v-model="filter.period">
							<span><em>{{getPastYear(3)}}</em></span>
						</label>
					</div>
					<!-- <div class="period__calendars">
						<label class="calendar">
							<input id="start-date" class="calendar__text calendars__start-date" type="text" role="text" tabindex="0" title="조회 시작일" maxlength="8" ref="start-date" v-model="filter.startDate">
						</label>
						<span class="fb__tel__separator"></span>
						<label class="calendar">
							<input id="end-date" class="calendar__text calendasr__end-date" type="text" role="text" tabindex="0" title="조회 종료일" maxlength="8" ref="end-date" v-model="filter.endDate">
						</label>
					</div>
					<div class="period__action">
						<button class="period__action__look-up" tabindex="0" :disabled="disabled" aria-label="검색" @click="lookUp($event)">조회</button>
						<button class="period__action__reset" tabindex="0" aria-label="조회조건 초기화" @click="reset($event)">초기화</button>
					</div> -->
				</div>
				<slot name="period-bottom"></slot>
			</div>
		</div>
	</section>
</template>
<script>
	import mixin from "../mixins/mixin";
	import moment from "moment";
	import Pikaday from "pikaday";

    export default {
		name : "period-component",
		props: {
			title: {
				type: String,
				default: ""
			},

			period: {
				type: String,
				validator: function (value) {
					return ["today", "6months", "thisYear", "1years", "2years", "3years"].includes(value);
				},
				default: "6months"
			},

			startDate: {
				type: String,
				validator: function (value) {
					return /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(value);
				},
				default: null
			},

			endDate: {
				type: String,
				validator: function (value) {
					return /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(value);
				},
				default: null
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				todayMoment: moment(),
				filter: {
					period: this.period,
					startDate: this.startDate,
					endDate: this.endDate,
				}
			}
		},

		watch: {
			"filter.period": {
				handler(newValue, oldValue) {
					const filter = this.filter;
					const momentToday = this.todayMoment.clone();
					const today = momentToday.clone().format("YYYY-MM-DD");
					let year = null;
					
					switch(newValue) {
						case "today":
							filter.startDate = momentToday.format("YYYY-MM-DD");
							filter.endDate = today;
						break;
						case "6months":
							filter.startDate = momentToday.clone().subtract(6, "months").format("YYYY-MM-DD");
							filter.endDate = today;
						break;
						case "thisYear":
							year = momentToday.clone().year();
							filter.startDate = `${year}-01-01`;
							filter.endDate = `${year}-12-31`;
						break;
						case "1years":
							year = momentToday.clone().subtract(1, "years").year();
							filter.startDate = `${year}-01-01`;
							filter.endDate = `${year}-12-31`;
						break;
						case "2years":
							year = momentToday.clone().subtract(2, "years").year();
							filter.startDate = `${year}-01-01`;
							filter.endDate = `${year}-12-31`;
						break;
						case "3years":
							year = momentToday.clone().subtract(3, "years").year();
							filter.startDate = `${year}-01-01`;
							filter.endDate = `${year}-12-31`;
						break;
					}

					if(oldValue) {
						this.$emit("look-up", {
							filter
						});
					}
				},
				immediate: true
			}
		},

		mounted() {
			this.$nextTick(() => {
				const self = this;
				const filter = this.filter;

				new Pikaday({
					field: this.$refs["start-date"],
					i18n: {
						months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"], 
						weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"], 
						weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"], 
					},
					yearSuffix: "년",
					showMonthAfterYear: true,
					minDate: moment().subtract(3,  "years").toDate(), 
					maxDate: moment().toDate(),
					onSelect: function(date) {
						self.filter.startDate = moment(date).format("YYYY-MM-DD");
					}
				});
				new Pikaday({
					field: this.$refs["end-date"],
					i18n: {
						months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"], 
						weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"], 
						weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"], 
					},
					yearSuffix: "년",
					showMonthAfterYear: true,
					minDate: moment().subtract(3, "years").toDate(),
					maxDate: moment().toDate(),
					onSelect: function(date) {
						self.filter.endDate = moment(date).format("YYYY-MM-DD");
					}
				});
				this.$emit("init", {
					filter
				});
			});
		},

		methods: {
			getPastYear(pastYear) {
				return this.todayMoment.clone().subtract(pastYear, "years").year();
			},

			// events
			lookUp(e) {
				const filter = this.filter;

				this.$emit("look-up", {
					e,
					filter
				});
			},

			reset(e) {
				const filter = this.filter;

				filter.period = "1month";
				this.$emit("reset", {
					e,
					filter
				});
			}
		}
    }
</script>
<style scoped lang="scss">
	.fb {
		&__period {

			.datetime-reset-button {
				display: none;
			}

			.period {
				&__title {
					font-size: 20px;
					font-weight: bold;
				}

				&__tip {
					padding-left: 10px;
					color: #787878;
					font-weight: normal;
					font-size: 12px;
				}

				&__container {
					margin-top: 20px;
					// border: 1px solid #d2d2d2;
				}

				&__main {
					display: flex;
					align-items: center;
					padding: 30px 0;
				}

				&__quick {
					display: flex;
					align-items: center;
					// padding: 0 45px;

					label {
						flex: 0 0 auto;
						display: block;
						font-size: 16px;

						em {
							font-family: 'GillSansWGL', sans-serif;
						}

						input {
							width: 0px;
							height: 0px;

							&:checked {
								& + span {
									font-weight: bold;
									color: #000
								}
							}

							&:disabled {
								& + span {
									cursor: no-drop;
								}
							}

							&:focus {
								& + span {
									outline: 1px dotted #212121;
									outline: 5px auto -webkit-focus-ring-color;
								}
							}
						}

						span {
							color: #b2b2b2;
							cursor: pointer;
						}

						&:after {
							display: inline-block;
							width: 0;
							height: 13px;
							margin: 0 20px; 
							border-left: 1px solid #d7d7d7;
							vertical-align: middle;
							content: "";
						}

						&:last-of-type {
							&:after {
								display: none;
							}
						}
					}
				}

				&__calendars {
					display: flex;
					align-items: center;
					padding: 0 7px;

					input {
						&[type="text"] {
							width: 150px;
							height: 40px;
							padding: 0 35px 0 15px;
							background: url("/pc/assets/images/common/btn-calendar.png") no-repeat 116px 10px;
							color: #787878;
							font-size: 16px;
						}
					}

					.fb__tel__separator {
						margin: 0 8px;
						flex: none;
					}

					.calendar {
						&__text {
							&:focus {
								border: 1px solid #d2d2d2;
							}
						}
					}
				}
				

				&__action {
					display: flex;
					flex: 1 1 auto;
					align-items: center;
					justify-content: flex-end;
					margin-right: 40px;

					button {
						width: 88px;
						height: 42px;
						margin-right: 5px;
						border: 1px solid #000;
						font-size: 14px;
						font-weight: 600;

						&:last-of-type {
							margin-right: 0;
						}
					}

					&__reset {
						background: #000;
						color: #fff;
					}
				}
			}
		}
	}
</style>