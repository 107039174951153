<template>
    <section class="fb__modal fixed top-0 left-0 w-full h-full flex flex-no-wrap flex-col items-center z-50" :class="{'noDim' : !isBackground}" @click.self="isBackgroundClose ? close() : focusCloseButton($event)" aria-label="알림" aria-modal="true">
		<div class="alert__wrapper p-3 m-auto bg-white" :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}`">
			<div class="alert__content text-center">
				<span v-html="message || ''"></span>
			</div>
			<footer class="text-center" v-if="isCloseButton">
				<button type="button" class="p-2" ref="close-button" v-focus @keydown.enter="close($event)" @click="close($event)">닫기</button>
			</footer>
		</div>
	</section>
</template>
<script>
    export default {
        name : "alert-component",

		props: {
			isBackgroundClose: {
				type: Boolean,
				default: false
			},

			isBackground: {
				type: Boolean,
				default: true
			},

			isCloseButton: {
				type: Boolean,
				default: true
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			message: {
				type: String,
				default: ""
			},
		},
		
		mounted() {
			// document.body.style.width = document.body.scrollWidth + "px";
			// document.body.classList.add("scroll-lock");
		},

		beforeDestroy() {
			// document.body.style.width = null;
			// document.body.classList.remove("scroll-lock");
		},
		
        methods: {
			focusCloseButton(e) {
				this.$refs["close-button"].focus();
			},

			close(e) {
				this.$emit("close-alert", {
					e
				});
			},
		},

		directives: {
			focus: {
				// 디렉티브 정의
				inserted: function (el, binding, vnode) {
					el.focus();
				}
			}
		},
    }
</script>
<style scoped lang="scss">
	section {
		$max-width: 960px;
		$min-width: 403px;

		background: rgba(0, 0, 0, .5);

		&.noDim {
			background: none;
		}

		.alert__wrapper {
			max-width: $max-width;
			min-width: $min-width;
			height: auto;
			padding: 20px 0 40px;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
		}

		.alert__content {
			height: 100px;
			padding: 0 30px;

			span {
				display: inline-block;
				line-height: 22px;
				vertical-align: middle;
				font-size: 16px;
				word-break: break-all;
			    word-wrap: break-word;
			}

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 0;
				height: 100%;
			}
		}

		footer {
			button {
				width: 80px;
				height: 45px;
				padding: 0;
				background: #000;
				line-height: 45px;
				font-size: 16px;
				color: #FFF;
			}
		}
	}
</style>