<template>
    <div class="fb__left-nav">
        <h2 class="fb__main__title">SERVICE CENTER</h2>
        <h2 class="fb__left-nav__title">
            <a href="/csCenter/index" title="고객센터" class="fb__t">
                고객센터
            </a>
        </h2>
        <ul class="fb__left-nav__box 1" v-if="vocToday">  
            <li class="fb__left-nav__link" :key="index" v-for="(menu, index) in menus" >
                <a :class="menu.url.indexOf(path) != -1 ? 'on' : null" :href="menu.url">{{menu.name}}</a>
            </li> 
        </ul> 
        <ul class="fb__left-nav__box 2" v-else>   
            <li class="fb__left-nav__link" :key="index" v-for="(menu, index) in menus2" >
                <a :class="menu.url.indexOf(path) != -1 ? 'on' : null" :href="menu.url">{{menu.name}}</a>
            </li> 
        </ul> 
        <div class="customer__left">  
            <h3 class="customer__left__title">
                고객센터
            </h3> 
            <p class="customer__left__contact">
				1566-0228
            </p>
			<p class="customer__left__info">
				평일 <span class="fb__font__n">08:30 ~ 17:30 </span><br/>
                주말 및 공휴일 휴무
			</p>
        </div>
	</div>
</template>
<script>
export default { 
    name : "cscenter-left-component",

    // props: {
    //     menus: {
    //         type: Object,
    //         default: () => {
    //             return {
                    
    //             }
    //         },
    //     },
    // },

    data() {
        return {
            path: window.location.pathname,
            vocToday: true,
            menus: [{
                name: "공지사항",
                url: "/csCenter/notice/notice",
            }, {
                name: "FAQ",
                url: "/csCenter/faq/faq",
            }, {
                name: "1:1 문의하기", 
                url: "/csCenter/inquiry/inquiryWrite",
            }, {
                name: "AS문의하기", 
                url: "/csCenter/inquiry/inquiryAsWrite",
            }, 
            {
                name: "입점문의",
                url: "/member/joinAgreement",
            }, {
                name: "멤버십(클럽)안내",
                url: "/csCenter/membershipInfo",
            }],
            menus2: [{
                name: "공지사항",
                url: "/csCenter/notice/notice",
            }, {
                name: "FAQ",
                url: "/csCenter/faq/faq",
            }, { 
                name: "1:1 문의하기", 
                url: "/csCenter/inquiry/inquiryWrite",
            },  {
                name: "AS문의하기", 
                url: "/csCenter/inquiry/inquiryAsWrite",
            }, 
            {
                name: "고객의 소리",
                url: "/csCenter/voc/vocIntro",
            },
             {
                name: "입점문의",
                url: "/member/joinAgreement",
            }, {
                name: "멤버십(클럽)안내",
                url: "/csCenter/membershipInfo",
            }],
        }
    },
  	created() {
        const eventDate = moment().format("YYYYMMDDHHmmss"); 
        if( eventDate <= '20211116090000') {  
            this.vocToday = true;   
        }else{
            this.vocToday = false;  
        }    
    },  
    methods: {

    }
}
</script>