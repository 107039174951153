/*
	다이퀘스트 필터
*/
const mixinControllerDFilters = {
	data() {
		return {
			has: Object.prototype.hasOwnProperty,
			
			fetches: {
				filterGroups: false,
			},

			requests: {
				filterGroups: {
					IL_CTGRY_ID: null,
					IL_BRAND_ID: null,
					SEARCH_WORD: null,
				},
			},

			/**
			 *	filterType
			 * 		1. search: 검색 페이지 ==> /shop
			 *		2. brandSearch: 브랜드 찾기(브랜드 메인) ==> /lineup/index
			 *		3. brand: 미니샵 -> TAB(SHOP) ==> /lineup/minishop
			 *		4. cate: 상품 리스트 페이지 ==> /shop/goodsList
			 *
			 * 	filterGroupParamKey
			 * 		1. 필터를 조회 시 요청하는 카테고리 및 브랜드의 유니크 값
			 * 		2. 해당 값이 없으면 filterType 가 대체합니다.
			 * 
			 * 	filterNextKey
			 * 		1. 필터 조회 성공 시 다음으로 조회 할 값
			 * 		2. resetPagination, reload를 호출 param으로 사용
			 * 
			 * 	filterOptions
			 * 		1. isReload
			 * 			1-1. 필터의 값이 변경되거나 초기화 될 시 resetPagination, reload 호출
			 * 			1-2. true: resetPagination, reload 호출합니다.
			 * 			1-3. false: resetPagination, reload 호출하지 않습니다.
			 * 
			 * 		2. isHistoryURL
			 * 			2-1. 필터의 값이 변경되거나 초기화 될 시 history 및 URL 변경
			 * 			2-2. true: history 및 URL 변경
			 * 			2-3. false: history 및 URL 변경하지 않음
			 * 
			 * 	filterSelectedCategories
			 * 		- 'filterCategory.key||depth||카테고리명' 구조로 되어있습니다.
			 * 
			 * 		1. first
			 * 			1-1. 선택 된 1depth 카테고리
			 * 		2. second
			 * 			2-1. 선택 된 2depth 카테고리 
			 * 		3. third
			 * 			3-1. 선택 된 3depth 카테고리 
			 * 
			 * 	filterCategoryDepths
			 * 		- switch 사용 시 int 및 string 2개의 dataType을 체크해야 하기 때문에
			 * 		  string 타입만 체크하기 위해 사용합니다.
			 * 
			 * 	filterPrice
			 * 		- 금액 세팅 값
			 * 
			 * 	filterCategory
			 * 		- 카테고리 세팅 값
			 * 
			 * 	filterFilter
			 * 		- 필터 디자인 세팅 값
			 * 		  해당 key를 기준으로 컬러 및 사각형 모양을 선택합니다.
			 * 
			 * 	filterBrand
			 * 		- 브랜드 세팅 값
			 * 
			 * 	filterGroups
			 * 		- 필터 정보가 들어있는 변수
			 * 
			 * 	filterSearch
			 * 		- 검색 기능이 들어있는 변수
			 * 		  검색단어가 들어 있습니다.
			 * 	
			 *	filterElements
			 *		- 해당 변수에 DOM 객체가 들어 있습니다.
			 *
			 * 		1. range
			 * 			1-1. noUiSlider를 사용합니다.
			 * 			1-2. noUiSlider에는 noUiSlider 객체가 들어 있습니다.
			 * 			1-3. mim, max는 noUiSlider의 최소 최대 값 입니다.
			 * 
			 * 	isFilterCategoryActive1
			 * 		- 현재 선택 된 1Depth 카테고리 index 값 입니다.
			 * 		- 네이밍을 변경해야 될 필요가 있습니다. is가 왜 들어감?
			 * 
			 *  isFilterCategoryActive2
			 * 		- 현재 선택 된 2Depth 카테고리 index 값 입니다.
			 * 		- 네이밍을 변경해야 될 필요가 있습니다. is가 왜 들어감?
			 * 
			 *  isFilterCategoryActive3
			 * 		- 현재 선택 된 3Depth 카테고리 index 값 입니다.
			 * 		- 네이밍을 변경해야 될 필요가 있습니다. is가 왜 들어감?
			 * 
			 * 	filterCategoryViewConut
			 * 		- 기본으로 보여줄 카테고리 개수 입니다. (더보기 누르기 전)
			 * 		- WARN: *** 현재 사용하지 않고 있습니다. 
			 * 
			 * 	filtersApplied
			 * 		- 현재 선택 된 필더 집합입니다.
			 */
			filterType: null,
			filterGroupParamKey: "IL_CTGRY_ID",
			filterNextKey: "goods",
			filterOptions: {
				isReload: true,
				isHistoryURL: true,
			},
			filterSelectedCategories: {
				first: null,
				second: null,
				third: null,
			},
			filterCategoryDepths: ["first", "second", "third"],

			//
			filterPrice: { key: "pr", type: "price" },
			filterCategory: { key: "ct", type: "category" },
			filterFilter: { key: "CL" },
			filterBrand: { key: "b", type: "search" },
			filterGroups: {},
			filterSearch: {},
			filterElements: {
				range: {
					noUiSlider: null,
					min: 0,
					max: 0
				},
			},
			filterFree : {key:"1-2001", type : "condition"},

			isFilterCategoryActive1: null,
			isFilterCategoryActive2: null,
			isFilterCategoryActive3: null,
			filterCategoryViewConut: 5,
			filtersApplied: [],
		}
	},

	watch: {
		/**
		 *	필터가 선택 및 삭제 될 때마다 동작함니다.
		 *	리셋 버튼이 눌렀을 경우에는 동작하지 않게 되어 있습니다. *** 수정필요
		 */
		filtersApplied: {
			handler(newValue, oldValue) {
				// 리셋버튼이 눌렀을 경우에는 동작하지 않습니다. 현재는 상세 페이지 다녀와서도 동작 안 함
				//if(!this.isLoaded || (!newValue.length && 1 < oldValue.length)) return;

				const method = newValue.length > oldValue.length ? "add" : "delete";
				const filters = _.difference("add" == method ? newValue : oldValue, "add" == method ? oldValue : newValue);

				$(".filterCnt").text('');
				$(".lnb__filter").removeClass("on");
				$(".filterNm[group_id=pr]").text("가격"); 
				$("input[name=price]").prop("checked", false);
				this.filterElements.range = []; 
				
				//디코딩되서도 선택되야 함
				this.$nextTick(() => {
					var filtersApplied = this.filtersApplied; var conditionType = "";
					$(".filterKind button").each(function(idx,val){
						var disTitle = $(val).find(".filterNm").text().trim(), filter = $(this), cnt = 0, selTitle="";
						filtersApplied.map(function(val, idx){
							selTitle = (val.GROUP_ID == "b" || val.GROUP_ID == "pr") ? val.TITLE : val.GROUP_ID.replace("Result","");
							if( selTitle == disTitle){
								cnt++;
								filter.parents("dl").addClass("on")
								filter.find(".filterCnt").text(cnt);
								if(val.GROUP_ID == "pr"){ //가격
									// PC는 필터선택되어도 "가격"으로 표시
									filter.find(".filterCnt").text('');

									$("input[name=price]").siblings().each(function(idx2, val2){
										if($(val2).text() == val.NAME){ 
											$(val2).siblings().prop("checked", true);
										}
									});

									if($("input[name=price]:checked").length == 0){
										$("#price5").prop("checked", true);
									}
									if(val.isDirect){
										$("#price5").prop("checked", true);
									}
									if(val.selId){
										$("#" + val.selId).prop("checked", true);
									}
								}
								if(val.ID == "1-2001"){ //무료배송
									filter.find(".filterCnt").text('');
									conditionType = '1';
								}
							}
						});
					});
					this.requests.goods.CONDITION_TYPE = conditionType;
				});

				if(filters && filters.length) {
					if(this.filterOptions.isHistoryURL) {
						for(let i = 0, maxCnt = filters.length; i < maxCnt; i++) {
							let filter = filters[i];

							if(i + 1 == maxCnt) this.filterSetHistory(this.filterModifyUrlSearch([method], true, [filter.ID], [filter.NAME]));
							else this.filterModifyUrlSearch([method], true, [filter.ID], [filter.NAME]);
						}
					}

					this.resetPagination(this.filterNextKey);
					this.reload(null, this.filterNextKey);	
				}
			}
		}
	},

	methods: {
		/**
		 *	다이퀘스트 연동
		 *	필터 데이터를 요청합니다.
		 */
		async requestFilters() {
			try {
				let filters = [];
				let filterGroups = null;
				let others = null;
				let categories = null;
				let brands = null;		
				let bestBrands = null;						
				let price = null;
				let sortFilterGroups  = {};
				let noFilterGroups = {};

				const parameters = this.requests.filterGroups;
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/search/filter",
					data: this.filterMakeRequestData()
				});
				const rows = response.filterObj;

				if(0 == rows.rs || 1 == rows.rs) {
					others = this.filterMakeOtherFilters(this.filterGetOtherFilters(rows));
					categories = this.filterMakeCategoryFilters(this.filterGetCategoryFilters(rows));
					brands = this.filterMakeBrandFilters(this.filterGetBrandFilters(rows));
					bestBrands = this.filterMakeBestBrandFilters(this.filterGetBrandFilters(rows));
					// FIXME: filterGetRangePriceFilters가 필요 없어 보입니다....
					// price = this.filterMakeRangePriceFilters(this.filterGetRangePriceFilters(rows));
					price = this.filterMakeRangePriceFilters(rows);
					
					filters = filters.concat(categories || [], others || [], brands || [], bestBrands || [], price || []);
					filterGroups = filters && filters.length ? _.groupBy(filters, "GROUP_ID") : null;
					
					if(filterGroups){
						$.each(filterGroups,function(idx,val){
							filterGroups[idx] = _.orderBy(filterGroups[idx], [item => parseInt(item.SORT)], ['asc']);
						});
					}

					if(filterGroups && filterGroups.b) { //브랜드
						filterGroups.b = _.orderBy(filterGroups.b, [item => parseInt(item.groupCnt)], ['desc']);
					}
					

					if(price && price.length) { //가격
						this.setPricePreset(price);
						this.filterInitRange(price[0].MIN, price[0].MAX);
						this.filterInitActiveCategory();
					}
	
					if(rows.attrIdArr){//attr_detl에 있던 속성들 sort를 위해 배열가공
						$.each(rows.attrIdArr, function(idx,val){
							$.each(filterGroups, function(idx2,val2){
								if(val.NAME == val2[0].TITLE.replace("Result", "")){
									if(val.filterGroups ==undefined){val.filterGroups = []}
									val.filterGroups.push(val2)
								}
							});
						});
	
						rows.attrIdArr.map(function(val, idx){
							if(sortFilterGroups[val.NAME] == undefined){sortFilterGroups[val.NAME] = [];}
							sortFilterGroups[val.NAME].push(val.filterGroups[0]);
						});
	
						if(sortFilterGroups["b"] == undefined){sortFilterGroups["b"] = [];}
						sortFilterGroups["b"].push(filterGroups.b);
	
						if(sortFilterGroups["pr"] == undefined){sortFilterGroups["pr"] = [];}
						sortFilterGroups["pr"].push(filterGroups.pr);
	
						//this.filterGroups = sortFilterGroups;
						filterGroups = sortFilterGroups;
					}else{//매핑된 필터가 없을 때
						if(filterGroups){
							$.each(filterGroups, function(idx, val){
								if(noFilterGroups[idx] == undefined){noFilterGroups[idx] = [];}
								noFilterGroups[idx].push(val);
							});
							filterGroups = noFilterGroups;
						}
					}


					// if(filterGroups && filterGroups.b) filterGroups.b.push(_.groupBy(filterGroups.b, "CHAR_CODE"));
					
					this.filtersApply(filters);
					this.$set(this.filterGroups, parameters[this.filterGroupParamKey] || this.filterType, filterGroups);
					this.fetches.filterGroups = true;
					
				}
				else {
					console.error("requestFilters fail...", rows.rs);

					this.fetches.filterGroups = "error";
				}
			}
			catch(ex) {
				console.error("requestFilters exception...", ex);

				this.fetches.filterGroups = "error";
			}
		},

		//중간 계산값에서 백의 자리에서 반올림
		setPricePreset(price){
			var presetArr = [], min = Number(price[0].MIN), max = Number(price[0].MAX);
			presetArr[0] = { ...price[0] };
			
			for(var i = 1; i<5; i++){
				presetArr.push({ ...price[0] });
				if(i==1){
					presetArr[i].MIN = Math.round(min/100) * 100;
					presetArr[i].MAX = Math.round((min + (max - min) * 0.25)/100) * 100;
				}
				if(i==2){
					presetArr[i].MIN = Math.round((min + (max - min) * 0.25)/100) * 100 + 1;
					presetArr[i].MAX = Math.round((min + (max - min) * 0.5)/100) * 100;
				}
				if(i==3){
					presetArr[i].MIN = Math.round((min + (max - min) * 0.5)/100) * 100 + 1;
					presetArr[i].MAX = Math.round((min + (max - min) * 0.75)/100) * 100;
				}
				if(i==4){
					presetArr[i].MIN = Math.round((min + (max - min) * 0.75)/100) * 100 + 1;
					presetArr[i].MAX = Math.round(max/100) * 100;
				}
				presetArr[i].NAME = presetArr[i].MIN + "원 ~ " + presetArr[i].MAX + "원";
			}
			
			this.filterElements.preset = presetArr;
			this.filterElements.preset[0].MIN = numberWithCommas(this.filterElements.preset[0].MIN);
			this.filterElements.preset[0].MAX = numberWithCommas(this.filterElements.preset[0].MAX);
		},

		// get

		// 가격 범위필터에 사용하는 키 return
		filterGetRangePriceKeys() {
			return ["startPrice", "endPrice"];
		},

		// FIXME: 테스트 후 이상 없으면 해당 함수 제거
		filterGetRangePriceFilters(diquestFilters) {
			let filteringKeys = this.filterGetRangePriceKeys();
			const priceFilters = {};

			for(let key of filteringKeys) {
				priceFilters[key] = diquestFilters[key];
			}

			// for(let [key, value] of Object.entries(diquestFilters)) {
			// 	priceFilters[key] = value;
			// }

			return priceFilters;
		},

		/**
		 * 가격 범위 필터 데이터 생성
		 *	
		 * @param {Object} diquestFilters
		 * @return {Array} priceFilters
		 */
		filterMakeRangePriceFilters(diquestFilters) {
			let filteringKeys = this.filterGetRangePriceKeys();
			let priceFilters = null;

			if ( diquestFilters[filteringKeys[0]] && diquestFilters[filteringKeys[1]] ) {
				priceFilters = [{
					DIS_TYPE: this.filterPrice.type,
					GROUP_ID: this.filterPrice.key,
					ID: this.filterPrice.key,
					TITLE: "가격",
					NAME: "가격",
					MIN: diquestFilters ? (diquestFilters[filteringKeys[0]]) || 0 : 0,
					MAX: diquestFilters ? (diquestFilters[filteringKeys[1]]) || 999999999 : 999999999
				}];
			}

			return priceFilters;
		},

		// FIXME: 사용하지 않는 함수로 보입니다 삭제해 주세요
		filterGetRangePrice(diquestFilters) {
			if(!diquestFilters) return null;
			
			const result = null !== diquestFilters.startPrice && undefined !== diquestFilters.startPrice && null !== diquestFilters.endPrice && undefined !== diquestFilters.endPrice
						? {
							min: diquestFilters.startPrice,
							max: diquestFilters.endPrice,
						}
						: null;

			return result;
		},

		/**
		 * 카테고리, 브랜드, 가격 필터는 제외한 나머지 필터 데이터 return
		 * 
		 * @param {Object} diquestFilters
		 * @return {Array} otherFilters
		 */
		filterGetOtherFilters(diquestFilters) {
			let filteringKeys = [];
			const otherFilters = {};
			filteringKeys = filteringKeys.concat(this.filterGetCategoryKeys(), this.filterGetBrandKeys(), this.filterGetRangePriceKeys());

			for(let [key, value] of Object.entries(diquestFilters)) {
				if(!filteringKeys.includes(key)) {
					otherFilters[key] = value;
				}
			}

			return otherFilters;
		},

		
		/**
		 * 검색타입 필터에서 검색어에 따른 결과값 return
		 * 
		 * @param {Object} filters 
		 * @return {Array} searchResult
		 */
		filterGetSearchTypeResult(filters) {
			const searchFilters = _.cloneDeep(filters);
			const text = this.filterSearch[searchFilters[0].GROUP_ID];

			// searchFilters.splice(searchFilters.length - 1, 1);

			return text ? searchFilters.filter(v => -1 != v.NAME.indexOf(text)) : searchFilters;
		},

		// 다이퀘스트 브랜드 키 반환
		filterGetBrandKeys() {
			return ["brandResult", "bestBrandResult"];
		},

		// 다이퀘스트 브랜드 데이터 반환
		filterGetBrandFilters(diquestFilters) {
			return {
				brandResult: diquestFilters.brandResult,
				bestBrandResult: diquestFilters.bestBrandResult
			} 
		},

		// 다이퀘스트 카테고리 키 반환
		filterGetCategoryKeys() {
			return ["lcateResult", "mcateResult", "scateResult", "dcateResult"];
		},

		// 다이퀘스트 카테고리 키 데이터 반환
		filterGetCategoryFilters(diquestFilters) {
			return {
				lcateResult: diquestFilters.lcateResult,
				mcateResult: diquestFilters.mcateResult,
				scateResult: diquestFilters.scateResult,
				dcateResult: diquestFilters.dcateResult
			} 
		},

		/**
		 * parentGroupNm의 하위 카테고리 viewCount의 개수만큼 목록을 불러옵니다.
		 * 
		 * @param {any} parentGroupNm 
		 * @param {Number} viewCount 
		 */
		filterGetCategoryChildren(parentGroupNm, viewCount) {
			const currentFilterGroupKey = this.requests.filterGroups[this.filterGroupParamKey] || this.filterType;
			const categories = this.filterGroups[currentFilterGroupKey][this.filterCategory.key];
			const findCategories = categories.filter(v => v.PARENT == parentGroupNm);

			if(findCategories && findCategories.length) {
				if(viewCount) return findCategories.slice(0, viewCount);
				else return categories.filter(v => v.PARENT == parentGroupNm);
			}
			else {
				return [];
			}
		},

		// FIXME: 미니샵에 modifyParentCategory에 있는데.... 사용하지 않는 함수로 보입니다. 확인 후 삭제해 주세요.
		filterGetCategory(name) {
			const categories = this.filterGroups.search[this.filterCategory.key];

			return categories.find(v => v.ORIGINAL_NAME == name);
		},
		
		// 카테고리 ID를 불러옵니다.
		filterGetCategoryId(keyName) {
			if(!keyName) return;
					
			const value = keyName.split("||");

			return value && 3 === value.length ? value[0] : "";
		},

		// 카테고리 depth를 불러옵니다.
		filterGetCategoryDepth(keyName) {
			if(!keyName) return;
					
			const value = keyName.split("||");

			return value && 3 === value.length ? value[1] : "";
		},

		// 카테고리명을 불러옵니다.
		filterGetCategoryName(keyName) {
			if(!keyName) return;
			
			const value = keyName.split("||");

			return value && 3 === value.length ? value[2] : "";
		},

		/**
		 * filterInitSelectedCategory에서 호출합니다.
		 * URL에 등록되어 있는 필터가 있을 시 filterSelectedCategories에 매핑합니다.
		 * 
		 * @param {Array}} categories 
		 * @param {any} code 
		 * @param {Number} depth 
		 * @param {String} name 
		 */
		filterSetSelectedCategory(categories, code, depth, name) {
			let category = categories.find(v => this.filterCategory.key === v.GROUP_ID && v.ID == code);
			let categoryDepth = Number.isInteger(depth) ? this.filterCategoryDepths[depth] : depth;
			let parentCategory = null;
			let refs = null;

			if(!category) return;

			switch(categoryDepth) {
				case "first":
					this.filterSelectedCategories.first = `${code}||${depth}||${name}`;
					refs = this.$refs[this.filterSelectedCategories.first];
				break;
				case "second":
					this.filterSelectedCategories.second = `${code}||${depth}||${name}`;
				break;
				case "third":
					this.filterSelectedCategories.third = `${code}||${depth}||${name}`;
				break;
			}

			parentCategory = categories.find(v => this.filterCategory.key === v.GROUP_ID && category.PARENT == v.ORIGINAL_NAME);

			if(category.PARENT && parentCategory) {
				this.filterSetSelectedCategory(categories, parentCategory.ID, depth - 1, parentCategory.NAME);
			}
		},

		/**
		 * history를 등록하면서 url을 변경합니다.
		 * 
		 * @param {Object} queryString 
		 */
		filterSetHistory(queryString) {
			this.setHistory(null, true, queryString ? `?${queryString.toString()}` : "?")
		},

		/**
		 * 검색필터 초기화
		 * 
		 * @param {Array} filters 
		 */
		filterInitSearch(filters) {
			const searchTypes = _.uniqBy(filters.filter(v => "search" == v.DIS_TYPE), "GROUP_ID");

			for(let i = 0, maxCnt = searchTypes.length; i < maxCnt; i++) {
				this.$set(this.filterSearch, searchTypes[i].GROUP_ID, null);
			}
		},

		/**
		 * noUISlider 범위필터 초기화
		 * 
		 * @param {Number} min 
		 * @param {Number} max 
		 */
		filterInitRange(min, max) {
			const range = this.filterElements.range;
			const rangeElement = this.$refs["price-range"];

			if ( rangeElement && rangeElement.length ) {
				if ( range.noUiSlider ) {
					range.noUiSlider.set([min, max]);
				}
				else {
					range.noUiSlider = window.noUiSlider.create(rangeElement[0], {
						start: [min || 0, max || 99999999],
						connect: true,
						range: {
							min: parseInt(min) || 0,
							max: parseInt(max) || 99999999
						},
						format: window.wNumb({
							decimals: 0,
							thousand: ',',
							to(value) {
								return value + ',-';
							},
							from(value) {
								return Number(value.replace(',-', ''));
							}
						})
					});

					range.noUiSlider.on('update', function(values) {
						range.min = values[0];
						range.max = values[1];
					});
				}
			}
		},

		/**
		 * 카테고리 초기화
		 * URL에 등록된 카테고리가 있을 경우 해당 데이터를 filterSelectedCategories에 매핑합니다.
		 * 
		 * @param {Array} categories 
		 * @param {String} value 
		 */
		filterInitSelectedCategory(categories, value) {
			let categorySplit = null;

			categorySplit = decodeURIComponent(value).split("||");

			if(categorySplit && 3 === categorySplit.length) {
				this.filterSetSelectedCategory(categories, categorySplit[0], categorySplit[1], categorySplit[2]);
			}
		},

		// URL에 등록된 카테고리가 있을 경우 해당 카테고리의 class toggle
		filterInitActiveCategory() {
			const filterGroupsParams = this.requests.filterGroups;
			let firstCategory = this.filterSelectedCategories ? this.filterSelectedCategories.first : null;
			let categorySplit = null;

			if(!firstCategory) return;

			categorySplit = firstCategory.split("||");

			if(categorySplit && 3 === categorySplit.length) {
				const categories = this.filterGroups[filterGroupsParams.IL_CTGRY_ID || filterGroupsParams.IL_BRAND_ID || this.filterType][this.filterCategory.key];
				const findFirstCategories = categories.filter(v => v.PARENT === null);
				const categoryIndex = findFirstCategories.findIndex(v => v.ORIGINAL_NAME === `${categorySplit[0]}_${categorySplit[2]}`);

				if(-1 !== categoryIndex) this.filterToggleCategoryDetails(categoryIndex);
			}
		},

		// 가격 필터 min, max 및 noUISlider 초기화
		filterInitPriceRange(price) {
			const range = this.filterElements.range;

			if(price) {
				range.min = price.min;
				range.max = price.max;

				this.$nextTick(() => {
					this.filterInitRange(price.min, price.max);
				});
			}
		},

		/**
		 * get 방식으로 다이퀘스트에 요청 할 URL을 생성합니다.
		 * 
		 * @return {String} URL
		 */
		filterMakeUrl() {
			const params = this.requests.filterGroups;
			let url = [`/search/filter?searchPage=${this.filterType}`];

			switch(this.filterType) {
				case "search":
				case "brandSearch":
					if(params.SEARCH_WORD) url.push(`searchTerm=${params.SEARCH_WORD}`);
				break;
				case "brand":
					if(params.IL_BRAND_ID) url.push(`searchBrand=${params.IL_BRAND_ID}`);
				break;
				case "cate":
					url.push(`searchCate=${params.IL_CTGRY_ID}`);
				break;
			}

			return url.join("&");
		},

		/**
		 * post 방식으로 다이퀘스트에 요청 할 data를 생성합니다.
		 * 
		 * @return {Object} data
		 */
		filterMakeRequestData() {
			const params = this.requests.filterGroups;
			const data = {
				searchPage: this.filterType
				, searchMainType : mainType
			}

			let url = [`/search/filter?searchPage=${this.filterType}`];

			switch(this.filterType) {
				case "search":
					data.searchTerm = params.SEARCH_WORD || "";
				break;
				case "cate":
					data.searchCate = params.IL_CTGRY_ID || "";
				break;
				case "brandSearch":
					data.searchTerm = params.SEARCH_WORD || "";
				break;
				case "brand":
					data.searchBrand = params.IL_BRAND_ID || "";
				break;
			}

			return data;
		},

		/**
		 * 카테고리, 브랜드, 가격 필터는 제외한 나머지 필터 데이터를 생성합니다.
		 * 
		 * @param {Object} diquestFilters
		 * @return {Array} otherFilters
		 */
		filterMakeOtherFilters(diquestFilters) {
			if(!diquestFilters) return [];

			let otherFilters = _.cloneDeep(diquestFilters);
			let otherFilter = null;
			let nameSplit = null;
			let name = null;
			let color = null;
			let result = [];

			for(let [key, value] of Object.entries(otherFilters)) {
				otherFilter = otherFilters[key];

				if(otherFilter && otherFilter.length) {
					if(!(key == "bestBrandResult" || key == "bestBrandResult" || key == "attrIdArr")) {
						result = result.concat(otherFilters[key].map(v => {
							nameSplit = v.groupNm.split("||");
							name = nameSplit[3];
							color = nameSplit[4];

							v.GROUP_ID = key;
							v.TITLE = key;
							v.ID = `${nameSplit[0]}-${nameSplit[2]}`;
							v.NAME = name;
							v.ORIGINAL_NAME = v.groupNm;
							v.COLOR = color;
							v.DIS_TYPE = color ? "RC" : "CL";
							v.SORT = nameSplit[0];

							return v;
						}));
					}
				}
				else {
					continue;
				}
			}

			return result;
		},

		/**
		 * 브랜드 필터 데이터를 생성합니다.
		 * 생성 할 때 ㄱㄴㄷ 순으로 데이터를 정렬하기 위한 과정이 포함되어 있읍니다.
		 * 
		 * @param {Object} diquestFilters
		 * @return {Array}} filters
		 */
		filterMakeBrandFilters(diquestFilters) {
			if(!diquestFilters) return [];

			let filters = [];
			let originalCharCode = null;
			let charCode = null;
			let brandNameSplit = null;
			let brandName = null;

			if(diquestFilters.brandResult && diquestFilters.brandResult.length) {
				filters = diquestFilters.brandResult.map(v => {
					brandNameSplit = v.groupNm.split("||");
					brandName = brandNameSplit[1];

					originalCharCode = (window.Hangul.disassemble(brandName)[0]).toLowerCase().charCodeAt();
					charCode = originalCharCode;

					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;

					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = this.filterBrand.type;
					v.TITLE = "브랜드";
					v.ID = brandNameSplit[0];
					v.NAME = brandName;
					v.ORIGINAL_NAME = v.groupNm;
					v.CONSONANT = (window.Hangul.disassemble(brandName)[0]).toLowerCase();
					v.CHAR_CODE = charCode;

					return v;
				});
			}

			return filters;
		},

		/**
		 * 베스트 브랜드 필터 데이터를 생성합니다.
		 * 
		 * @param {Object} diquestFilters
		 * @return {Array} filters
		 */
		filterMakeBestBrandFilters(diquestFilters) {
			if(!diquestFilters) return [];

			let filters = [];
			let originalCharCode = null;
			let charCode = null;
			let brandNameSplit = null;
			let brandName = null;

			if(diquestFilters.bestBrandResult && diquestFilters.bestBrandResult.length) {
				filters = diquestFilters.bestBrandResult.map(v => {
					brandNameSplit = v.groupNm.split("||");
					brandName = brandNameSplit[1];

					originalCharCode = (window.Hangul.disassemble(brandName)[0]).toLowerCase().charCodeAt();
					charCode = originalCharCode;

					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;

					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = this.filterBrand.type;
					v.TITLE = "브랜드";
					v.ID = brandNameSplit[0];
					v.NAME = brandName;
					v.ORIGINAL_NAME = v.groupNm;
					v.CONSONANT = "BEST";
					v.CHAR_CODE = 0;

					return v;
				});
			}

			return filters;
		},

		/**
		 * 카테고리 필터 데이터를 생성합니다.
		 * 모든 카테고리가 동일한 레벨로 세팅하며 PARENT 값으로 부모를 구분합니다.
		 * 
		 * @param {Object} diquestFilters
		 * @return {Array} filters
		 */
		filterMakeCategoryFilters(diquestCategories) {
			if(!diquestCategories) return [];

			const categoriesOrder = this.filterGetCategoryKeys();
			let result = [];

			//
			let categories = null;
			let currentCategoryKey = null;
			let categoryChild = null;
			let splitCategory = null;
			let parentCategory = null;

			//
			let nameSplit = null;

			for(let i = 0, maxCnt = categoriesOrder.length; i < maxCnt; i++) {
				currentCategoryKey = categoriesOrder[i];

				if(!this.has.call(diquestCategories, currentCategoryKey)) continue;

				categories = diquestCategories[currentCategoryKey];

				if(categories && categories.length) {
					switch(currentCategoryKey) {
						case "lcateResult":
							categories = categories.map(v => {
								nameSplit = v.groupNm.split("||");

								return {
									TITLE: "카테고리",
									DIS_TYPE: this.filterCategory.type,
									GROUP_ID: this.filterCategory.key,
									ID: nameSplit[0],
									PARENT: null,
									ORIGINAL_NAME: v.groupNm,
									NAME: nameSplit[1],
								}
							});
							result = result.concat(categories);
						break;
						case "mcateResult":
						case "scateResult":
						case "dcateResult":
							for(let j = 0, maxCntJ = categories.length; j < maxCntJ; j++) {
								categoryChild = categories[j];

								if(!categoryChild || !categoryChild.groupNm) continue;

								splitCategory = categoryChild.groupNm.split(/\s*(@@[^@@]+$)/);
								nameSplit = splitCategory[1].replace("@@", "").split("||");
								parentCategory = result.find(v => v.ORIGINAL_NAME === splitCategory[0]);

								if(parentCategory) {
									result.push({
										TITLE: "카테고리",
										DIS_TYPE: this.filterCategory.type,
										GROUP_ID: this.filterCategory.key,
										ID: nameSplit[0],
										PARENT: parentCategory.ORIGINAL_NAME,
										ORIGINAL_NAME: categoryChild.groupNm,
										NAME: nameSplit[1],
									});
								}
							}
						break;
					}
				}
			}

			return result;
		},
		
		/**
		 * 목록 별 생성된 필터를 하나로 합칩니다.
		 * 
		 * @param {Array} filterRows 
		 * @param {Array} brandRows 
		 * @param {Array} brandBestRows 
		 * @return {Array} filter
		 */
		filterMakeFilters(filterRows, brandRows, brandBestRows) {
			let filters = [];
			let row = null;

			if(filterRows && filterRows.length) {
				for(let i = 0, maxCnt = filterRows.length; i < maxCnt; i++) {
					row = filterRows[i];

					if(!row.IL_ATTR_DETL || !row.IL_ATTR_DETL.length) continue;

					filters = filters.concat(row.IL_ATTR_DETL.map(v => {
						v.GROUP_ID = row.IL_ATTR_CD;
						v.DIS_TYPE = row.DIS_TYPE;
						v.TITLE = row.NAME;

						return v;
					}));
				}
			}

			if(brandRows && brandRows.length) {
				filters = filters.concat(brandRows.map(v => {
					const originalCharCode = (window.Hangul.disassemble(v.BRAND_NAME)[0]).toLowerCase().charCodeAt();
					let charCode = originalCharCode;
	
					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;
	
					v.ID = v.IL_BRAND_ID;
					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.NAME = v.BRAND_NAME;
					// BEST 랑 분기
					v.CONSONANT = (window.Hangul.disassemble(v.BRAND_NAME)[0]).toLowerCase();
					v.CHAR_CODE = charCode;
	
					return v;
				}));
			}

			if(brandBestRows && brandBestRows.length) {
				filters = filters.concat(brandBestRows.map(v => {
					v.ID = v.IL_BRAND_ID;
					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.NAME = v.BRAND_NAME;
					v.CONSONANT = "BEST";
					v.CHAR_CODE = 0;
	
					return v;
				}));
			}

			return filters;
		},
		
		// 카테고리 정보를 초기화 합니다.
		filterResetCategory() {
			const filterSelectedCategories = this.filterSelectedCategories;

			this.filterToggleCategoryDetails(null, null);

			filterSelectedCategories.first = null;
			filterSelectedCategories.second = null;
			filterSelectedCategories.third = null;

			this.isFilterCategoryActive1 = null;
			this.isFilterCategoryActive2 = null;
			this.isFilterCategoryActive3 = null;
		},

		/**
		 * 필터 변경 시 URL를 변경합니다.
		 * methods
		 *  1. add: ids와 values에 해당가는 값을 url에 추가합니다.
		 *  2. delete: ids와 values에 해당가는 값을 url에 삭제합니다.
		 *  3. reset: url에 등록된 모든 필터 정보를 삭제합니다.
		 * 
		 * multiple
		 *  1. ...
		 * 
		 * ids
		 *  1. url에 등록할 필터 id 리스트
		 * 
		 * values
		 *  1. url에 등록할 필터 value 리스트
		 * 
		 * @param {String} methods 
		 * @param {Boolean} multiple 
		 * @param {Array} ids 
		 * @param {Array} values 
		 */
		filterModifyUrlSearch(methods, multiple, ids, values) {
			const queryString = this.getQueryString();
			let keys = null;
			let value = null;
			let parameters = null;
			let addedIndex = null;

			for(let i = 0, maxCnt = methods.length; i < maxCnt; i++) {
				if(!["add", "delete", "reset"].includes(methods[i])) {
					console.error("method not allowed...", methods[i]);

					return queryString;
				}

				value = values && values.length ? (values[i] || "").toString() : "";

				if("reset" == methods[i]) {
					keys = this.filtersApplied.map(v => v.ID);

					for(let j = 0, maxCnt = keys.length; j < maxCnt; j++) {
						queryString.delete(keys[j]);
					}
				}
				else if(multiple) {
					parameters = queryString.getAll(ids[i]);
					addedIndex = parameters.findIndex(v => decodeURIComponent(v) == value);

					if(queryString.has(ids[i])) {
						if(-1 != addedIndex) parameters.splice(addedIndex, 1);
						queryString.delete(ids[i]);

						for(let parameter of parameters) {
							queryString.append(ids[i], encodeURIComponent(parameter.replace(/ /g, "")));
						}
					}
					
					switch(methods[i]) {
						case "add":
							queryString.append(ids[i], encodeURIComponent(value.replace(/ /g, "")));
						break;
					}
				}
				else {
					queryString.delete(ids[i]);

					if("add" == methods[i]) queryString.append(ids[i], encodeURIComponent(value.replace(/ /g, "")));
				}
			}

			return queryString;
		},

		/**
		 * 가격 필터의 noUISlider를 해제합니다.
		 */
		filterDestroyNoUiSlider() {
			const range = this.filterElements.range;

			if(range.noUiSlider) {
				range.noUiSlider.destroy();
				range.noUiSlider = null;
			}
		},

		/**
		 * 카테고리 선택 시 detail attr(open)를 변경합니다.
		 * 
		 * @param {Event} e 
		 * @param {Number} index 
		 */
		filterToggleCategoryDetails(e, index) {
			const categories = this.$refs.categories;
			let category = null;

			if(!categories || !categories.length) {
				return;
			}
			
			this.isFilterCategoryActive1 = index;

			for(let i = 0, maxCnt = categories.length; i < maxCnt; i++) {
				category = categories[i];

				if(category.hasAttribute("open")) category.removeAttribute("open");
			}

			// settimeout 없으면 ie에서 동작을 안하므로....
			setTimeout(() => {
				if(null !== index && undefined !== index) categories[index].setAttribute("open", "");
			}, 1);
		},

		/**
		 * 카테고리 선택 시 filtersApplied 및 filterSelectedCategories에 선택된 값을 매핑합니다.
		 * 
		 * @param {Event} e 
		 * @param {Array} category 
		 * @param {Number} depth 
		 * @param {Number} index 
		 */
		filterSelectedCategory(e, category, depth, index) {
			const filterSelectedCategories = this.filterSelectedCategories;
			const categoryDepth = Number.isInteger(depth) ? this.filterCategoryDepths[depth] : depth;
			const id = this.filterCategory.key;
			const name = `${category.ID}||${depth}||${category.NAME}`;
			const filtersApplied = this.filtersApplied.find(v => v.ID == id);
			const filtersAppliedIndex = this.filtersApplied.findIndex(v => v.ID == id);
			const currentFilterGroupKey = this.requests.filterGroups[this.filterGroupParamKey] || this.filterType;
			const categoryParent = this.filterGroups[currentFilterGroupKey][this.filterCategory.key].find(v => this.filterCategory.key === v.GROUP_ID && v.ORIGINAL_NAME === category.ORIGINAL_NAME);

			if(filtersApplied && filtersApplied.NAME == name) return;
			if(-1 !== filtersAppliedIndex) this.filtersApplied.splice(filtersAppliedIndex, 1);
			
			this.filtersApplied.push({
				TITLE: "카테고리",
				DIS_TYPE: this.filterCategory.type,
				GROUP_ID: this.filterCategory.key,
				ID: id,
				PARENT: categoryParent.ORIGINAL_NAME,
				ORIGINAL_NAME: category.ORIGINAL_NAME,
				NAME: name,
			});

			switch(categoryDepth) {
				case "first":
					if(filterSelectedCategories.first != name || filterSelectedCategories.third || filterSelectedCategories.second) {
						filterSelectedCategories.first = name;
						filterSelectedCategories.second = null;
						filterSelectedCategories.third = null;
					}
				break;
				case "second":
					if(filterSelectedCategories.second != name || (filterSelectedCategories.second == name && filterSelectedCategories.third)) {
						filterSelectedCategories.second = name;
						filterSelectedCategories.third = null;
					}
				break;
				case "third":
					if(filterSelectedCategories.third != name) filterSelectedCategories.third = name;
				break;
			}

			if(this.filterOptions.isHistoryURL) this.filterSetHistory(this.filterModifyUrlSearch(["add"], false, [id], [name]));
			if(this.filterOptions.isReload) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);
			}
		},

		/**
		 * 초기화 시 서버에서 받아온 필터와 URL로부터 받아온 필터에 동일한 필터가 있을 시 선택처리(filtersApplied에 등록) 합니다.
		 * 
		 * @param {Array} rows 
		 */
		filtersApply(rows) {
			const queryString = this.getQueryString();
			let filtersApplied = null;
			let category = null;
			let categoryParent = null;
			let categorySplit = null;
			let categorySplitParam = null;
			let categoryNameSplit = null;
			let priceRange = null;
			let minMax = null;

			for(let pair of queryString.entries()) {
				if(this.filterPrice.key == pair[0]) {
					priceRange = this.filterElements.range;
					minMax = decodeURIComponent(pair[1]).split("~");

					if(!priceRange.min && !priceRange.max && minMax && minMax.length) {
						priceRange.min = minMax[0];
						priceRange.max = minMax[1];
						filtersApplied = {
							ID: pair[0],
							GROUP_ID: pair[0],
							NAME: `${minMax[0]} ~ ${minMax[1]}`,
							MIN: minMax[0],
							MAX: minMax[1],
							TITLE : "가격"
						};
					}
				}
				else if(this.filterCategory.key == pair[0] && pair[1]) {
					categorySplitParam = decodeURIComponent(pair[1]).split("||");

					if(categorySplitParam && 3 === categorySplitParam.length) {
						category = rows.find(v => this.filterCategory.key === v.GROUP_ID && v.ID == categorySplitParam[0]);
						categorySplit = category.ORIGINAL_NAME.split(/\s*(@@[^@@]+$)/);
						categoryNameSplit = (categorySplit[1] || categorySplit[0]).replace("@@", "").split("||");
						categoryParent = rows.find(v => this.filterCategory.key === v.GROUP_ID && v.ORIGINAL_NAME === categorySplit[0]);

						if(category) {
							filtersApplied = {
								TITLE: "카테고리",
								DIS_TYPE: this.filterCategory.type,
								GROUP_ID: this.filterCategory.key,
								ID: this.filterCategory.key,
								PARENT: categoryParent.ORIGINAL_NAME,
								NAME: decodeURIComponent(pair[1]),
								ORIGINAL_NAME: category.ORIGINAL_NAME,
							};

							this.filterInitSelectedCategory(rows, pair[1]);
						}
					}
				}
				else if(this.filterFree.key == pair[0]) { // 무료배송은 필터를 적용하지 않는다. (ORIGINAL_NAME 미존재)
					filtersApplied = {
						ID: "1-2001",
						GROUP_ID: "무료배송",
						NAME: "무료배송",
						MIN: "",
						MAX: ""
					};
					this.requests.goods.CONDITION_TYPE = 1;
				}
				else {
					filtersApplied = rows.find(v => v.ID == pair[0] && v.NAME.replace(/\s/g, "") == decodeURIComponent(pair[1]));
				}

				if(filtersApplied) this.filtersApplied.push(filtersApplied);
			}
		},

		/**
		 * 필터를 접었다 핍니다.
		 * 
		 * @param {Event} e 
		 */
		filterFoldEvent(e) {
			const target = e.target;
			const targetBox = target.parentElement.parentElement;
			
			targetBox.classList.toggle("active");
		},

		/**
		 * filterSearch에 groupId별로 검색된 단어를 매핑합니다.
		 * 
		 * @param {Event} e 
		 * @param {String | Number} groupId 
		 */
		filterSearchBrand(e, groupId) {
			const inputElement = this.$refs["brand-search-text" + groupId];
			const value = Array.isArray(inputElement) ? inputElement[0].value : inputElement.value;
		
			this.$set(this.filterSearch, groupId, value);
		},

		/**
		 * 필터 더보기 버튼 toggle
		 * 
		 * @param {Event} e 
		 * @param {String | Number} groupId 
		 */
		filterToggleMore(e, groupId) {
			const elements = this.$refs["filter-area" + groupId];

			if(!elements) return;
			if(elements.length) elements[0].classList.toggle("lnb__filter__area--open");
			else elements.classList.toggle("lnb__filter__area--open");
			
		},

		/**
		 * range 객체 안의 noUiSlider min, max 값을 변경합니다.
		 * 
		 * @param {Event} e 
		 * @param {String} type 
		 * @param {Object} range 
		 */
		filterModifyRange(e, type, range) {
			range.noUiSlider.set(["min" == type ? e.target.value : null, "max" == type ? e.target.value : null]);
		},

		/**
		 * 가격 필터 적용버튼 선택 시 적용필터 목록에 등록합니다.
		 * 
		 * @param {String} isDirect  
		 */
		filterApplyPrice(isDirect, selId) {
			const range = isDirect ? this.filterElements.preset[0] : this.filterElements.range;
			const id = this.filterPrice.key;
			const name = `${numberWithCommas(range.MIN)}원 ~ ${numberWithCommas(range.MAX)}원`;
			const filtersApplied = this.filtersApplied.find(v => v.ID == id);

			if(filtersApplied && filtersApplied.NAME == name) return;
			if(filtersApplied) this.filtersApplied.splice(this.filtersApplied.findIndex(v => v.ID == id), 1);
			
			this.filtersApplied.push({
				ID: id,
				GROUP_ID: id,
				NAME: name,
				MIN: range.MIN,
				MAX: range.MAX,
				TITLE : "가격",
				isDirect : isDirect,
				selId : selId
			});

			if(this.filterOptions.isHistoryURL) this.filterSetHistory(this.filterModifyUrlSearch(["add"], false, [id], [name]));
			if(this.filterOptions.isReload) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);

				$(".filterNm[group_id=pr]").text(name);
				$(".filterNm[group_id=pr]").parents("dl").addClass("on");
			}
		},

		/**
		 * 적용된 필터 삭제 시 적용필터 목록에서 제거 합니다.
		 * 
		 * @param {Event} e 
		 * @param {Object} item 
		 */
		filterDeleteAppliedItem(e, item) {
			const filtersApplied = this.filtersApplied;

			// 카테고리인 경우
			if(item.ID === this.filterCategory.key) this.filterResetCategory();

			filtersApplied.splice(filtersApplied.findIndex(v => v.ID == item.ID), 1);

			this.filterResetRange();

			if(this.filterOptions.isHistoryURL) this.filterSetHistory(this.filterModifyUrlSearch(["delete"], true, [item.ID], [(item.NAME || "").replace(/ /g, "")]));
			if(this.filterOptions.isReload) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);
			}
		},

		/**
		 * 가격 필터 초기화
		 */
		filterResetRange() {
			const currentFilterGroupKey = this.requests.filterGroups[this.filterGroupParamKey] || this.filterType;
			const filters = this.filterGroups[currentFilterGroupKey];
			let price = null;

			if(filters) price = this.has.call(filters, this.filterPrice.key) ? filters[this.filterPrice.key] : null;
			if(price && price.length) this.filterInitRange(price[0].MIN, price[0].MAX);
		},

		/**
		 * 적용된 필터를 초기화 합니다.
		 * 
		 * isReload
		 *   1. 필터 초기화 후 reload 및 resetPagination함수를 호출합니다.
		 * 
		 * @param {Event} e 
		 * @param {Boolean} isReload 
		 */
		filterResetAppliedItems(e, isReload) {
			const queryString = this.filterModifyUrlSearch(["reset"]);
			const appliedFilterCount = this.filtersApplied.length;

			this.filterResetCategory();
			this.filterResetRange();
			this.filterResetSearchBrand();
			this.filtersApplied = [];

			$(".filterCnt").text('');
			$(".lnb__filter").removeClass("on");
			$(".filterNm[group_id=pr]").text("가격"); 
			$("input[name=price]").prop("checked", false);
			this.requests.goods.CONDITION_TYPE = ''; //무료배송
			
			if(this.filterOptions.isHistoryURL) this.filterSetHistory(queryString);

			// FIXME: watch 쪽에서 호출하느 ㄴ것이랑 어떻게 처리해야 될 것인치 고민해봐야 함...
			if(1 < appliedFilterCount) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);  
			}
		},

		/**
		 * 브랜드 검색결과 및 검색어 초기화
		 * 
		 * @param {Event} e 
		 * @param {String | Number} groupId 
		 */
		filterResetSearchBrand(e, groupId) {
			let inputElement = null;
			let resultMatch = null;

			if(groupId) {
				inputElement = this.$refs["brand-search-text" + groupId];

				if(!inputElement) return;

				inputElement.value = "";
				this.filterSearch[groupId] = null;
			}
			else {
				for(let [key, value] of Object.entries(this.$refs)) {
					resultMatch = key.match(/^(brand-search-text)(.*)/);

					if(resultMatch && resultMatch.length) {
						inputElement = Array.isArray(value) ? value[0] : value;

						if(!inputElement) return;

						inputElement.value = "";

						this.filterSearch[resultMatch[2]] = null;
					}
				}
			}
		},
	},
}

export default mixinControllerDFilters;