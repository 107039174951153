<template>
    <section class="pagination__wrapper pagination__wrapper__type--2" v-if="0 < pagination.max">
		<div class="pagination">
			<button class="pagination__btn__left" :disabled="disabled" v-if="isLoop || (1 < pagination.current || pagination.infinite === true)" @click="movePage($event, pagination.current-1)">
				이전페이지
			</button>
			<div class="pagination__inner">
				<span class="pagination__now ">
					{{pagination.current}}
				</span>
				<span class="pagination__max ">
					{{pagination.max}}
				</span>
			</div>
			<button  class="pagination__btn__right" :disabled="disabled" v-if="isLoop || (pagination.current < pagination.max || pagination.infinite === true)" @click="movePage($event, pagination.current+1)">
				다음페이지
			</button>
		</div>
	</section>
</template>
<script>
    export default {
        name : "pagination-type-2-component",

		props: {
            pagination: {
                type: Object,
				default: () => {
					return {
						id: null,
						current: 1,
						range: 5,
						max: 10,
						infinite: false,
					}
				},
				required: true
			},

			isLoop: {
				type: Boolean,
				default: false
			},
			
			disabled: {
				type: Boolean,
				default: false
			}
        },
		
        methods: {
            movePage(e, page) {
				let movePage = page;

				if(0 === page) movePage = this.pagination.max;
				else if(this.pagination.max < page) movePage = 1;

				this.$emit('move-page', {
					e: e,
					id: this.pagination.id,
					page: movePage
				});
			}
        }
    }
</script>
<style lang="scss" scoped>
	.pagination__wrapper {
		.pagination {
			display: flex;
			align-items: center;

			&__btn {
				
				&__left {
					margin-right: 7px;
					background: url(/pc/assets/images/common/icon-page-arrow.png) no-repeat 0 0;
				}

				&__right {
					margin-left: 7px;
					background: url(/pc/assets/images/common/icon-page-arrow.png) no-repeat right 0;
				}
			}

			button {
				position: relative;
				width: 6px;
				height: 12px;
				text-indent: -9999px;
			}

			&__inner {
				display: inline-block;
				position: relative;
				width: 64px;

				span {
					display: block;
					float: left;
					width: 50%;
					text-align: center;
				}

				&:after {
					display: block;
					clear: both;
					content: "";
				}

				&:before {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: 1px;
					height: 11px;
					margin-top: -4px;
					background: #797979;
					content: "";
				}
			}

			&__now {
				color: #000;
			}

			&__max {
				color: #777777;
			}

			&__list {

				span {
					.test {
						color: #ff3e2f;
					}
				}
			}
		}
	}
</style>