<template>
    <section>
		<div class="flex flex-row w-full" v-if="items && items.length">
			<button :class="pagination.current === page ? 'w-full bg-blue-600' : 'w-full'" :disabled="disabled" :key="page" v-for="page of items" @click="movePage($event, page)">
				{{page}}
			</button>
		</div>
	</section>
</template>
<script>
    export default {
        name : "pagination-component",

		props: {
            pagination: {
                type: Object,
				default: () => {
					return {
						id: null,
						current: 1,
						range: 5,
						max: 10
					}
				},
				required: true
			},
			
			disabled: {
				type: Boolean,
				default: false
			}
        },
		
        mounted() {
			this.createPaination();
		},

		watch: {
			pagination: {
				handler(newValue, oldValue) {
					this.createPaination();
				},
				deep: true,
			},
		},

        data() {
            return {
            	items: null
            }
		},
		
        methods: {
			createPaination() {
				const pagination = this.pagination;
				const items = [];
				const range = pagination.range;
				const current = pagination.current;
				const lackMin = 1 > (current - range) ? -(current - range - 1) : 0;
				const lackMax = -1 == Math.sign(pagination.max - (current + range)) ? -(pagination.max - (current + range)) : 0;
				const min = current - range - lackMax;
				const max = current + range + lackMin;

				for(let i = (1 > min ? 1 : min), maxCnt = pagination.max > max ? max : pagination.max; i <= maxCnt; i++) {
					items.push(i);
				}

				this.items = items;
			},

            movePage(e, page) {
				this.$emit('move-page', {
					e: e,
					id: this.pagination.id,
					page
				});
			}
        }
    }
</script>
<style scoped lang="scss">
</style>