<template>
    <section class="fb__more" v-if="pagination.current < pagination.max">
		<div class="full-width py-3 text-center" v-if="loading">
			<slot name="loading"></slot>
		</div>
		<div class="fb__more__btn">
			<button class="px-5 py-3 m-auto" @click="movePage($event)" :disabled="disabled">
				더보기
			</button>
		</div>
	</section>
</template>
<script>
    export default {
        name : "page-more-component",

		props: {
            pagination: {
                type: Object,
				default: () => {
					return {
						id: null,
						current: 1,
						max: 10
					}
				},
			},

			loading: {
				type: Boolean,
				default: false
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},
		
		watch: {
			pagination: {
				handler(newValue, oldValue) {
					if(newValue.current > newValue.max) newValue.current = newValue.max;
				},
				deep: true,
			},
		},

        data() {
            return {
            	items: null
            }
		},
		
        methods: {
            movePage(e) {
				this.$emit('move-page', {
					e: e,
					id: this.pagination.id,
					page: this.pagination.current + 1
				});
			}
        }
    }
</script>
<style scoped lang="scss">
	.fb {
		&__more {
			//width값은 각각 페이지에서 조정
			position: relative;
			width: 100%;
			height: 70px;
			text-align: center;

			&:after {
				position: absolute;
				top: 50%;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 1px;
				background: #e7e7e7;
				margin-top: -0.5px;
				content: "";
			}

			&__btn {

				button {
					display: inline-block;
					color: #787878;
					font-size: 13px;

					&:before {
						display: block;
						width: 49px;
						height: 49px;
						margin-bottom: 10px;
						background: url("/pc/assets/images/common/btn-common-more.png") no-repeat 0 0;
						border-radius: 100%;
						text-align: center;
						content: "";
					}
				}
			}
		}
	}
</style>