import Vue from "vue";
import VueLazyload from "vue-lazyload";
import VueScrollTo  from "vue-scrollto";
import VueObserveVisibility from 'vue-observe-visibility'
import store from "./store/store";

// lib
import moment from "moment";
import uuidV1 from "uuid/v1";
import uuidV3 from "uuid/v3";
import uuidV4 from "uuid/v4";
import uuidV5 from "uuid/v5";
import Hangul from "hangul-js";
import wNumb from "wnumb"; 
import xss from "xss";

// import canvasToBlob from "canvas-to-blob";
import VueKonva from 'vue-konva';
// components
// event bus
import eventBus from "./event-bus/event-bus";

// extends
import extendLayouts from "./extends/layouts";

// mixins
import mixin from "./mixins/mixin";
import mixinHistory from "./mixins/history";
import mixinFiles from "./mixins/files";
import mixinEventBus from "./mixins/event-bus"; 

// mixin pages
import mixinControllerCategories from "./mixins/controller/categories";
import mixinControllerFilters from "./mixins/controller/filters";
import mixinControllerDFilters from "./mixins/controller/d-filters";

// mixin dynamic
import mixinDynamicSwiper from "./mixins/swiper"; 

// common components
import modalComponent from "./components/modal-component.vue";
import alertComponent from "./components/alert-component.vue";
import confirmComponent from "./components/confirm-component.vue";
import paginationComponent from "./components/pagination-component.vue";
import paginationType2Component from "./components/pagination-type-2-component.vue";
import paginationType3Component from "./components/pagination-type-3-component.vue";
import pageMoreComponent from "./components/page-more-component.vue";
import gridComponent from "./components/grid-component.vue";
import gridSingleComponent from "./components/grid-single-component.vue";
import periodComponent from "./components/period-component.vue";
import Pikaday from "pikaday";
// import "lodash";

// page components
import cscenterLeftComponent from "./components/cscenter-left-component.vue";
import cscenterTopComponent from "./components/cscenter-top-component.vue";
import mypageTopComponent from "./components/mypage-top-component.vue";
import mypageLeftComponent from "./components/mypage-left-component.vue";

// dynamic layout components
import commentsComponent from "./components/dynamic/comments-component.vue";

//md5 
import md5 from 'md5';

Vue.config.productionTip = false;  
Vue.prototype.$super = function(parent) {
	const methods = parent.methods || {};
    const returnMethods = {};

	for (const key of Object.keys(methods)) {
		returnMethods[key] = methods[key].bind(this);
	}

	return returnMethods;
}; 

Vue.mixin({ delimiters: ["[[","]]"] });

// Vue.use(VueRouter);
Vue.use(VueLazyload, {
	/* https://github.com/hilongjw/vue-lazyload#readme */
	preLoad: 1,
	error: "/pc/assets/images/common/noimg.gif",
	loading: "/pc/assets/images/common/loading.gif",
	attempt: 1
});
Vue.use(VueObserveVisibility);
Vue.use(VueKonva);
Vue.use(VueScrollTo);
// common components
Vue.component("modal-component", modalComponent);
Vue.component("alert-component", alertComponent);
Vue.component("confirm-component", confirmComponent);
Vue.component("pagination-component", paginationComponent);
Vue.component("pagination-type-2-component", paginationType2Component);
Vue.component("pagination-type-3-component", paginationType3Component);
Vue.component("page-more-component", pageMoreComponent);
Vue.component("grid-component", gridComponent);
Vue.component("grid-single-component", gridSingleComponent);
Vue.component("period-component", periodComponent);

// pages components
Vue.component("cscenter-left-component", cscenterLeftComponent);
Vue.component("cscenter-top-component", cscenterTopComponent);
Vue.component("mypage-top-component", mypageTopComponent);
Vue.component("mypage-left-component", mypageLeftComponent);

// dynamic layout components
Vue.component("comments-component", commentsComponent);

// vue
window.Vue = Vue;
window.VueStore = store;
window.VueEventBus = eventBus;
window.VueScrollTo = VueScrollTo;
window.VueExtends = {
	layouts: extendLayouts,
};
window.VueMixins = {
	mixin,
	mixinHistory,
	mixinFiles,
	mixinEventBus,

	common: mixin,
	history: mixinHistory,
	files: mixinFiles,
	eventBus: mixinEventBus,
	swiper: mixinDynamicSwiper,

	controller: {
		categories: mixinControllerCategories,
		filters: mixinControllerFilters,
		dFilters: mixinControllerDFilters
	},
};

// library
window.moment = moment;
window.uuidV1 = uuidV1;
window.uuidV3 = uuidV3;
window.uuidV4 = uuidV4;
window.uuidV5 = uuidV5;
window.Hangul = Hangul;
window.Pikaday = Pikaday;
window.wNumb = wNumb;
window.xss = xss;
window.md5 = md5;
// window.toBlob = canvasToBlob;