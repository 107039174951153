<template>
	<div :class="`${marginTop ? 'margin-top: ' + marginTop + 'px;' : ''}${marginRight ? 'margin-right: ' + marginRight + 'px;' : ''}${marginBottom ? 'margin-bottom: ' + marginBottom  + 'px;': ''}${marginLeft ? 'margin-left: ' + marginLeft + 'px;' : ''} ${classes ? classes : ''}`" :style="`width: calc(100%/${rowNumber} - ${((marginRight + marginLeft) ? (marginRight + marginLeft) : 0)}px);`">
		<slot></slot>
	</div>
</template>
<script>
    export default {
        name : "single-grid-component",

		props: {
			rowNumber: {
				type: Number,
				default: 1
			},

			marginTop: {
				type: Number,
				default: 0
			},

			marginRight: {
				type: Number,
				default: 0
			},

			marginBottom: {
				type: Number,
				default: 0
			},

			marginLeft: {
				type: Number,
				default: 0
			},

			classes: {
				type: String,
				default: ""
			},
        }
    }
</script>