/**
 * Created by forbiz on 2019-02-11.
 */
import common from './divide/common';
import layout from './divide/layout';
import Swiper from 'swiper/js/swiper.js';
import noUiSlider from 'no-ui-slider';
import Player from '@vimeo/player';
window.Swiper = Swiper;
window.noUiSlider = noUiSlider;
window.loadImage = require('blueimp-load-image');
window.Player = Player;
const appInit = () => {
    const appName = document.body.getAttribute("id");

    if(appName) [common, layout].forEach(method  => {
        if(method) method();
    });

};
document.addEventListener('DOMContentLoaded', () => {
    appInit();
});