<template>
  <div class="fb__left-nav">
    <h2 class="fb__main__title">MY PAGE</h2>
    <h2 class="fb__left-nav__title">
      <a href="/mypage/index" title="마이페이지" class="fb__t">
        마이페이지
      </a>
    </h2>
    <div class="fb__mypage">
      <div class="fb__mypage__left">
        <div class="fb__mypage__left__info">
          <p v-if="'Y' == member.isLogin">
            <!--
  requestMyInfo에서 불러오는 값
  {{member.SS_LOGIN_NM}}님<br />
-->

            <!-- localStorage 에서 불러옴. 마이페이지 왼쪽 메뉴가 늦게 불러지는 경우 때문에 추가 network.js 참조 -->
            {{memberName}}님<br />
            안녕하세요!
            <button class="btn_logout" onclick="fnLogout();">로그아웃</button>
          </p>
          <p v-else>
            비회원 주문조회
          </p>
        </div>
        <ul class="mypage__left" v-if="'Y' == member.isLogin">
          <li class="mypage__left__menu">
            <h3 class="mypage__left__subtitle">나의 쇼핑내역</h3>
            <a href="/mypage/order/orderHistory" v-bind:class="{'mypage__left--active' : pageName == 'order-history'}">
              <span>주문배송 조회</span>
            </a>
            <a href="/mypage/order/orderClaim" v-bind:class="{'mypage__left--active' : pageName == 'order-claim'}">
              <span>취소/반품 조회</span>
            </a>
<!--            <a href="/mypage/order/orderSurcharge" v-bind:class="{'mypage__left&#45;&#45;active' : pageName == 'order-surcharge'}">-->
<!--              <span>추가결제금액 조회</span>-->
<!--            </a>-->
          </li>

          <li class="mypage__left__menu">
            <h3 class="mypage__left__subtitle">나의 혜택관리</h3>

            <a href="/mypage/mybenefit/myPoint" v-bind:class="{'mypage__left--active' : pageName == 'my-point'}">
              <span> 나의 포인트</span>
            </a>
            <a href="/mypage/myCoupon/getMyCouponMain" v-bind:class="{'mypage__left--active' : pageName == 'my-coupon'}">
              <span>나의 쿠폰</span>
            </a>
            <a href="https://guudmembers.casamia.co.kr/web/mypage/coupon/couponList?t=0" target="_blank">
              <span>디지털상품권 등록</span>
            </a>
            <a id = "btn_pop_point" href="javascript:;" class="btn_pop_point" onclick="comPopupOpen('popupPoint');">
              <span>페이백 조회</span>
            </a>
          </li>

          <!-- 페이백 -->
          <div class="com__popup__layer popup__notice guudpoint" id="popupPoint">
            <div class="layer__wrap">
              <div class="layer__header">
                <h2>3월 굳프라이스 페이백 조회</h2> <a href="javascript:;" class="layer__close">닫기</a>
              </div>
              <div class="layer_scroll_skelteon">
                <div class="layer__content">
                  <div class="point_area">
                    <div class="pay_price">
                      <div class="tit">페이백 대상 결제금액</div><div class="price" id="paidPrice">0</div><div>원</div>
                    </div>
                    <div class="earn_point">
                      <div class="tit">예상 적립 굳포인트</div><div class="price" id="paybackPrice">0</div><div>원</div>
                    </div>
                  </div>
                  <div class="explan">
                    <div>
                      ※ 페이백은 실 결제금액(쿠폰 할인, 포인트 사용금액, 배송비 제외) 기준으로 책정됩니다.<br>
                      <a href="https://www.guud.com/event/goodsEventDetail?DP_DISPLAY_ID=17188&TYPE=3&SUB_TYPE=4">
                        <div class="more">자세한 유의사항 보러가기</div>
                      </a>
                    </div>
                  </div>
                  <div class="payback_prd_area">
                    <div class="header">
                      <div class="pay_back1 on">페이백 대상 상품</div>
                      <div class="pay_back2">페이백 미대상 상품</div>
                    </div>
                    <div class="prd">
                      <div class="payback_prd">
                        <ul class="comm__goods__list" id = "paybackItemList_ul"></ul>
                      </div>
                      <div class="nopayback_prd">
                        <ul class="comm__goods__list" id = "paybackNItemList_ul"></ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mask"></div>
          </div>

          <li class="mypage__left__menu">
            <h3 class="mypage__left__subtitle">나의 관심내역</h3>

            <a href="/mypage/wishlist/recentlyList" v-bind:class="{'mypage__left--active' : pageName == 'recently-list'}">
              <span> 최근 본 내역</span>
            </a>
            <a href="/mypage/wishlist/productList" v-bind:class="{'mypage__left--active' : pageName == 'product-list'}">
              <span>나의 찜 상품</span>
            </a>
            <a href="/mypage/wishlist/brandList" v-bind:class="{'mypage__left--active' : pageName == 'brand-list'}">
              <span id="wishBrandNews">나의 관심 브랜드</span>
            </a>
            <a href="/self/mySelfPlanner" v-bind:class="{'mypage__left--active' : pageName == 'mySelfPlanner'}">
              <span>나의 셀프 플래너</span>
            </a>
            <a href="/mypage/wishlist/restockList" v-bind:class="{'mypage__left--active' : pageName == 'restock-list'}">
              <span>재입고 알림</span>
            </a>
          </li>

          <li class="mypage__left__menu">
            <h3 class="mypage__left__subtitle">나의 커뮤니티</h3>

            <a href="/mypage/community/inquiryList" v-bind:class="{'mypage__left--active' : pageName == 'inquiry-list'}">
              <span> 1:1 문의 내역</span>
            </a>
            <a href="/mypage/voc/vocList" v-bind:class="{'mypage__left--active' : pageName == 'vocList'}" v-if="vocToday">
              <span> 고객의 소리</span>
            </a>
            <a href="/mypage/community/qnaList" v-bind:class="{'mypage__left--active' : pageName == 'qnaList'}">
              <span>나의 상품 문의</span>
            </a>
            <a href="/mypage/myFeedback/getMyFeedbackMain" v-bind:class="{'mypage__left--active' : (pageName == 'review-list' || pageName == 'my-review')}">
              <span>나의 상품 리뷰</span>
            </a>
          </li>

          <li class="mypage__left__menu">
            <h3 class="mypage__left__subtitle">회원정보관리</h3>
            <!--
<button @click="movePage($event, 0)">회원정보 수정</button>
<button @click="movePage($event, 1)">비밀번호 변경</button>
-->
            <a href="javascript:moveShinsegaePointUrl('editInfo')" v-if="'Y' == member.isSsgMem">
              <span>회원정보 수정</span>
            </a>

            <a href="javascript:moveShinsegaePointUrl('chPw')" v-if="'Y' == member.isSsgMem">
              <span>비밀번호 변경</span>
            </a>

            <a href="/biz/mypage/chgInfo?TYPE=0" v-if="'Y' != member.isSsgMem">
              <span>회원정보 수정</span>
            </a>

            <a href="/biz/mypage/chgInfo?TYPE=1" v-if="'Y' == member.WEB_ID_YN && 'Y' != member.isSsgMem ">
              <span>비밀번호 변경</span>
            </a>

            <a href="/mypage/memberInfo/memberInfoDelivery" v-bind:class="{'mypage__left--active' : pageName == 'delivery-Info'}">
              <span>배송지 관리</span>
            </a>

            <a href="javascript:moveShinsegaePointUrl('mbrTrans')" v-if="'Y' == member.isSsgMem">
              <span>회원 통합 전환</span>
            </a>

            <a href="javascript:;" v-if="member.GUUDPAY_USER_KEY != '' && member.GUUDPAY_USER_KEY != null && tossPgYn ==='N'" @click="openGuudPay">
              <span>GuudPay 관리</span>
            </a>
            <a href="javascript:;" v-if="tossPgYn ==='Y'" @click="openGuudPay_toss">
              <span>GuudPay 관리</span>
            </a>
          </li>
        </ul>
        <ul v-else>
          <li class="mypage__left__menu">
            <h3 class="mypage__left__subtitle">나의 쇼핑내역</h3>
            <a href="/mypage/order/orderHistory" v-bind:class="{'mypage__left--active' : pageName == 'order-history'}">
              <span>주문조회</span>
            </a>
            <a href="/mypage/order/orderClaim" v-bind:class="{'mypage__left--active' : pageName == 'order-claim'}">
              <span>취소/반품 조회</span>
            </a>
            <a href="/mypage/order/orderSurcharge" v-bind:class="{'mypage__left--active' : pageName == 'order-surcharge'}">
              <span>추가결제금액 조회</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <alert-component :message="alert.message" aria-modal="알림" @close-alert="alert.isOpen = false" v-if="alert.isOpen"></alert-component>
  </div>
</template>
<script>
export default {
  name : "mypage-left-component",

  data() {
    return {
      isLogin: "",
      memberName: window.localStorage.getItem("memberName"),

      alert: {
        isOpen: false,
        message: null,
      },
      vocToday : false,
      tossPgYn : "N",
      TOSS_API_CLIENT_KEY : ""
    }
  },

  props: {
    member: {
      type: Object | null,
      required: true
    },

    pageName: {
      type: String,
    },

  },

  created() {
    const eventDate = moment().format("YYYYMMDDHHmmss");
    if( eventDate <= '20211116090000') {
      this.vocToday = false;
    }else{
      this.vocToday = true;
    }

    this.getMypageTossInfo();
  },

  mounted() {
    if (!document.querySelector('script[src="https://js.tosspayments.com/v2/standard"]')) {
      const script = document.createElement("script");
      script.src = "https://js.tosspayments.com/v2/standard";
      document.head.appendChild(script);
    }

    this.getBrandNewsList();
  },

  watch: {
    member: {
      handler(newValue, oldValue) {
        this.memberName = window.localStorage.getItem("memberName");
      },
    },
  },

  methods: {
    async requestMoveUrl(type) {
      try {
        const response = await this.$store.dispatch("network/request", {
          method: "get",
          url: "/biz/mypage/chgInfo?TYPE=" + type,
          data: {
            TYPE: type
          }
        });

        return {
          code: 1,
          url: response.redirectURL
        };
      } catch (ex) {
        console.error("requestMoveUrl exception...", ex);

        return {
          code: -1,
          message: ex.message || "오류가 발생하였습니다."
        };
      }
    },

    async movePage(e, type) {
      const alert = this.alert;
      const result = await this.requestMoveUrl(type);

      if (1 === result.code) {
        window.location.href = result.url;
      } else {
        alert.message = result.message;
        alert.isOpen = true;
      }
    },
    openGuudPay() {
      // GuudPay 관리 페이지를 열기 위한 로직을 구현
      var IFWin;
      var url = '/guudPay/guudPayInfo';
      var OpenOption = 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=450,height=800,top=100,left=400,';
      window.open(url, 'IfWindow', OpenOption);
    },

    openGuudPay_toss() {
      const clientKey = this.TOSS_API_CLIENT_KEY;
      const tossPayments = TossPayments(clientKey);

      this.getCustomerKey()
          .then(customerKey => {
            const brandpay = tossPayments.brandpay({
              customerKey,
              redirectUrl: window.location.origin + "/toss/guudPay/joinMemberResult_toss"
            });
            brandpay.openSettings();
          })
          .catch(error => {
            console.log("load error", error);
          });
    },

    getCustomerKey: function() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await this.$store.dispatch("network/request", {
            method: "POST",
            url: "/toss/getCustomerKey"
          });

          // 성공적으로 응답을 받았을 때
          const customerKey = response.tossCustomerKey;
          if (customerKey) {
            resolve(customerKey); // 성공적으로 키 반환
          } else {
            reject(new Error("Customer key is missing in the response")); // 키가 없는 경우 에러 처리
          }
        } catch (error) {
          reject(error); // API 호출 실패 시 reject
        }
      });
    },

    async getMypageTossInfo () {
        try {
          const response = await this.$store.dispatch("network/request", {
            method: "POST",
            url: "/toss/getMypageTossInfo"
          });

          this.tossPgYn = response.TOSS_PG_YN;
          this.TOSS_API_CLIENT_KEY = response.TOSS_API_CLIENT_KEY;

        } catch (error) {
        }
    },

    async getBrandNewsList() {

      if (this.member.isLogin == 'Y') { //로그인 상태 일 경우만 호출
        //사용자가 찜한 브랜드 중에 소식이 있는 브랜드 조회
        try {
          const response = await this.$store.dispatch("network/request", {
            method: "get",
            url: "/mypage/wishlist/getBrandNewsList",
            data: {}
          });

          if (response != null && response.RETURN_CODE == "000000000") {

            if (response.newsList != null && response.newsList.length > 0) {
              const wishBrandNewsElement = document.getElementById('wishBrandNews');

              if (wishBrandNewsElement) {
                wishBrandNewsElement.classList.add('new');
              }
            }
          }
        } catch (ex) {
          console.error("requestMoveUrl exception...", ex);

          return {
            code: -1,
            message: ex.message || "오류가 발생하였습니다."
          };
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.fb {
  &__mypage {

    &__left {

      &__info {
        height: 205px;
        padding-top: 26px;
        color: #000;
        font: {
          size: 30px;
          weight: 600;
        };
        line-height: 1.5;
        box-sizing: border-box;

        .btn_logout {
          display: block;
          margin-top: 18px;
          color: #787878;
          font-size: 14px;
          font-weight: 600;
          border: none;
        }
      }
    }

    .mypage__left {

      &__menu {
        padding: 20px 0 40px;
        border-top: 1px solid #e5e5e5;

        a, button {
          display: block;
          margin-top: 10px;
          color: #787878;
          font-size: 14px;

          &.btn_pop_point {
            margin-top: 10px;
          }
        }
      }

      .new {
        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          right: -5px;
          top: 1px;
          width: 4px;
          height: 4px;
          background-color: #ff3e2f;
          border-radius: 50%;
        }
      }

      &--active {
        color: #000 !important;
        font-weight: 600;
      }

      &__subtitle {
        margin-bottom: 16px;
        color: #000;
        font: {
          size: 17px;
          weight: bold;
        }
      }
    }
  }
}
</style>