<template>
    <section class="fb__mypage-top">
		<div class="guide" >
			<template v-if="false === fetches.orderStatistics">
				<div class="guide__membership">
					<span class="fb__skeleton"></span>
					<span class="fb__skeleton"></span>
				</div>
				<div class="guide__my-info">
					<div>
						<span class="fb__skeleton guide__my-info__order-count"></span>
						<span class="fb__skeleton"></span>
					</div>
					<div>
						<span class="fb__skeleton guide__my-info__mileage"></span>
						<span class="fb__skeleton"></span>
					</div>
					<div>
						<span class="fb__skeleton guide__my-info__available-coupon"></span>
						<span class="fb__skeleton"></span>
					</div>
				</div>
			</template>
			<template v-else-if="true === fetches.orderStatistics && mypageInfo">
				<div class="guide__membership">
					<span class="tit">나의 멤버십</span>
					<a href="/csCenter/membershipInfo"  class="guide__membership__go">멤버십 혜택보기</a>
					<div class="guide__tag">
						<template v-if='mypageInfo.sEmpYn == "Y"'>
							<em class="tag guide__level">
								Family
							</em>
						</template> 
						<template v-else>
							<em class="tag  guide__level">
								{{mypageInfo.GRP_NAME_M || "GOLD"}} 
							</em>
						</template>
						<template v-if='mypageInfo.CLUB_CD == "CLB001"'>
							<em class="tag guide__w">
								{{mypageInfo.CLUB_NM}}
							</em>
						</template>
           				<template v-if='mypageInfo.CLUB_CD == "CLB002"'>
							<em class="tag guide__m">
								{{mypageInfo.CLUB_NM}}
							</em>
						</template>
						<template v-if='mypageInfo.BENEFIT_TARGET_YN == "Y"'>
							<em class="tag guide__g">
								굳클럽
							</em>
						</template>
					</div>
				</div>

				<div class="guide__my-info">
					<div>
						<a href="/mypage/order/orderHistory">
							<span class="s_tit">주문내역</span>
							<span class="guide__my-info__order-count">{{mypageInfo.my_od_total || 0}}</span>
						</a>
					</div>
						<div>
						<a href="/mypage/myCoupon/getMyCouponMain">
							<span class="s_tit">쿠폰</span>
							<span class="guide__my-info__available-coupon">{{mypageInfo.my_cp_total || 0}}</span>
						</a> 
					</div>  
					<div>
						<a href="/mypage/mybenefit/myPoint">
							<span class="s_tit">굳포인트</span>
							<span class="guide__my-info__mileage">{{mypageInfo.tot_point | price}}</span>
						</a>
					</div> 
					
					<div v-if='mypageInfo.isSsgMem == "Y"'>
						<a href="javascript:;" class="guide__my-info__forcus">
							<span>신세계포인트</span>
							<span class="guide__my-info__mileage">{{mypageInfo.SSG_POINT || 0}}</span>  
						</a>
					</div> 
					
					<!--신세계 통합회원 sns 이슈 건으로 인해 주석 처리 [s]-->
					<!-- <div v-else-if='mypageInfo.isSsgMem == "N"'>
							<span>신세계포인트 <i class="sssss" @click="shinsagePointClick">i</i></span>
							<a href="javascript:fnTransSsgLogout();" class="guide__my-info__transform">
								신세계포인트 통합회원 전환
							</a>
					</div> 	 --> 
					<!--신세계 통합회원 sns 이슈 건으로 인해 주석 처리 [e]-->
					<div v-else>
						<a href="javascript:;" class="guide__my-info__forcus">
							<span></span>
						</a> 
					</div> 				
				</div>
			</template>
			<template v-else-if="'error' === fetches.orderStatistics">
				<div class="m-auto text-center">
					오류가 발생하였습니다.
					<div class="fb__mt--8">
						<button class="button__reload" clear @click="reload($event)">
							다시로드
						</button>
					</div>
				</div>
			</template>
		</div>
		<modal-component :width="modal.width"  :background="modal.background" @close-modal="modal.isOpen=false" v-if="modal.isOpen">
			<template v-slot:default>
				<div class="fb__modal mypage"> 
					<header class="fb__modal__title">신세계포인트 통합회원 안내</header>
					<div class="fb__modal__content">
						<em>신세계포인트 통합회원</em>에게만 드리는 굳닷컴 혜택과<br />
						이마트, 신세계백화점, SSG.COM 등 다양한 제휴사의 혜택을<br />
						하나의 ID로 간편하게 이용하실 수 있습니다.
					</div>
					<div>
						<button class="fb__button__theme__black fb__modal__close__button" @click="modal.isOpen=false">닫기</button>
					</div>
				</div>
			</template> 
		</modal-component>
	</section>
	
</template>
<script>
	import mixinCommon from "../mixins/mixin.js";

    export default {
		name : "mypage-top-component",
		mixins: [mixinCommon],
		
		data() {  
			return {
				//신세계통합 회원인지 체크 
				shinsageUser: false,  
				fetches: {
					orderStatistics: false,
				},

				requests: {
					orderStatistics: {}
				},

				orderStatistics: {
					IC: 0,
					DI: 0,
					DC: 0,
					BF: 0,
				},
				modal: {
					width: "480px",
					background: "#fff",
					isOpen: false,
				}, 
			}
		},

		props: {
            mypageInfo: {
                type: Object,
				default: () => {
					return {}
				},
			},
        },

		created() {
			this.requestOrderStatistics();
		},

        methods: {
			async requestOrderStatistics() {
				try {
					const parameters = this.requests.orderStatistics;
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/biz/mypage/getMyShoppingHistoryCount",
						data: parameters
					});
					const rows = response.hiscount;

					for(let [key, value] of Object.entries(this.orderStatistics)) {
						const row = rows.find(v => key == v.CODE);

						if(row) this.orderStatistics[key] = row.COUNT || null;
					}

					this.fetches.orderStatistics = true;
				}
				catch(ex) {
					console.error("requestOrderStatistics exception...", ex);
					this.fetches.orderStatistics = "error";
				}
			},
			async shinsagePointClick() { 
				const _this = this;
				try {
					this.modal.isOpen = true;
					return;
				} 
				catch(ex) {
					console.log( "ex==>", ex );
				}
			},
			// events
			reload() {
				this.fetches.orderStatistics = false;
				this.requestOrderStatistics();
			},
		},
    }
</script> 
<style lang="scss">
	.fb {
		&__mypage-top {
			background: #f7f7f7;
			border-top: 3px solid #000;

			&__skeleton {
				background: #fff;	
			}
			
			.guide {
				display: flex;
				position: relative;
				justify-content: space-between;
				align-items: center;
				height: 146px;
				padding: 0 80px;
				letter-spacing: -0.025em;

				&__tag {
					margin-top: 12px;

					.tag{
						display: inline-block;
						height:30px;
						line-height:30px;
						padding:0 10px;
						font-size:14px;
						font-weight:600;
						font-family: "NotoSansCJKkr", sans-serif;
						color:#fff;
						vertical-align: middle;

						&.guide__level{
							font-size:18px;
							font-family: "GillSansWGL", sans-serif;
							font-weight:400;
							background-color:#000;
							letter-spacing: 0;
						}

						&.guide__w{
							background-color:#F5B3A6;
						}
						&.guide__m{
							background-color:#AFCEA4;
						}
					}
				}

				&__membership {
					flex: 0 0 auto;

					.tit {
						display: inline-block;
						font-size: 24px;
						font-weight: 600;
						vertical-align:middle;
						line-height:1.5;

						&.fb__skeleton {
							height: 20px;
							background: #fff;

							&:nth-of-type(1) {
								width: 205px;
							}

							&:nth-of-type(2) {
								width: 150px;
							}
						}
					}

					&__go {
						display:inline-block;
						margin-left: 12px;
						padding-right: 10px;
						background:url("/pc/assets/images/common/btn-arrow-right.png") no-repeat 100% center;
						color: #000000;
						vertical-align:middle;

						font: {
							size: 14px;
						};
					}
				}

				&__my-info {
					display: flex;
					text-align: center;
					&__forcus{ 
						pointer-events: none;
					}
					
					&__transform{
						display: block;
						margin-top: 5px;
						background: #fff;
						color: #000;
						padding: 5px;
						border:1px solid #000;
						font-weight: bold;
					}

					&__order-count, &__mileage, &__available-coupon {
						display: block;
						margin-top: 9px;
						font-family: 'GillSansWGL', sans-serif;
						font-size: 34px;
						font-weight: 400;

						& + span {
							display: block;
							font-size: 14px;

							&:nth-of-type(n + 2) {
								margin-top: 12px;
							}
						}
					}

					div {
						margin-left: 52px;

						.fb__skeleton {
							width: 50px;
							height: 35px;
							background: #fff;

							&:nth-of-type(n + 2) {
								height: 15px;
							}
						}

						&:first-of-type {
							margin-left: 0;
						}

						.s_tit{
							font-size:14px;
						}

						span{
							display: block;
							position: relative;
							i{ 
								display: inline-block;
								vertical-align: top;
								width: 18px; height: 18px;
								border-radius: 20px;
								background: #000;
								color: #fff;
								font-size: 14px;
								font-weight: 600;
								font-style: normal;
								cursor: pointer;
								padding: 1px 0 0 1px;
								text-align: center;
							}
						}
						
					}
				}
			}
		}
		&__modal{
			padding:35px 27px;
			&__title{
				padding: 0 30px 20px 0;
				font-size: 24px;
				font-weight: 700;
				font-family: "NotoSansCJKkr", serif;
			}
			&__content{
				width: 100%;
				border-top: 1px solid #000;
				font-size: 16px;
				color: #000;
				line-height: 30px;
				padding-top: 15px;
				em{ 
					font-weight: bold;
				}
			}
			&.mypage{ 
				.fb__modal__close__button{
					display: block;
					width: 78px;
					height: 44px;
					margin: 20px auto auto auto;
					border: 1px solid #000; 
					background: #000;
					font-size: 16px;
					font-weight: bold;
					color: #fff;
				}
			}
		}

	}

</style>