const mixinHistory = {
	data() {
		return {
			isHistoryLoaded: false,
			historyAllowDataType: ["string", "number", "boolean", "undefined", "object"],
			historyExClass: ["Swiper", "Player", "m", "HTMLButtonElement", "HTMLDivElement"],
			historyExKeys: ["has", "useHistoryStatePages", "isHistoryLoaded", "isLoaded", "noUiSlider", "observerOptions", "player"],
			useHistoryStatePages: [{
				page: "my-page-index",
				where: null,
			}, {
				page: "search",
				where: null,
			}, {
				page: "shop-index",
				where: null,
			}, {
				page: "goods-list",
				where: null,
			}, {
				page: "goods-event",
				where: null,
			}, {
				page: "goods-event-detail",
				where: null,
			}, {
				page: "order-history",
				where: null,
			}, {
				page: "order-claim",
				where: null,
			}, {
				page: "order-detail",
				where: null,
			}, {
				page: "ithome-index",
				where: null,
			}, {
				page: "ithome-detail",
				where: null,
			}, {
				page: "ranking",
				where: null,
			}, {
				page: "line-up",
				where: null,
			}, {
				page: "mini-shop",
				where: null,
			}, {
				page: "main",
				where: null,
			}, {
				page: "inquiry-list",
				where: null,
			}, {
				page: "review-list",
				where: null,
			}, {
				page: "order-surcharge",
				where: null,
			}, {
				page: "store-information",
				where: null,
			}, {
				page: "product-list",
				where: null,
			}, {
				page: "deal-list",
				where: null,
			}],
		}
	},

	beforeCreate() {
		window.onpopstate = (e) => {
			this.popState(e);
		};
	},

	created() {
		const useHistoryState = this.useHistoryStatePages.findIndex(v => v.page === this.$options.name);
		const historyState = history.state;
		
		if(0 <= useHistoryState) {
			if(historyState && 1 != window.performance.navigation.type) {
				Object.assign(this.$data, historyState.data);
				window.history.replaceState(null, window.location.href);
				this.isHistoryLoaded = true;
			}
		}

		this.$nextTick(() => {
			if(window.VueEventBus) window.VueEventBus.$emit("history/loaded", this.isHistoryLoaded);
		})
	},

	methods: {
		popState(e) {
		},

		setHistory(e, isPartialEx, url) {
			this.setHistoryState(url, this.getDoSaveHistory(isPartialEx));
		},

		isDoSaveHistory(allowDataType, exKeys, exClass, data, isPartialEx) {
			if(Array.isArray(data)) {
				for(let i = 0, maxCnt = data.length; i < maxCnt; i++) {
					let value = data[i];

					if(value instanceof Function) {
						if(isPartialEx) delete data[i];
						else return false;
					}
					else if(value instanceof HTMLElement) {
						if(isPartialEx) delete data[i];
						else return false;
					}
					else if(Array.isArray(value)) {
						return this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx);
					} 
					else if(value instanceof Object) {
						return this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx);
					}
					else if(exKeys.includes(typeof value) || !allowDataType.includes(typeof value)) {
						if(isPartialEx) delete data[i];
						else return false;
					}
				}
			}
			else if(data instanceof Object) {
				for(let [key, value] of Object.entries(data)) {
					if(exKeys.includes(key)) {
						if(isPartialEx) delete data[key];
						else return false;
					}
					else if(value && value.constructor && exClass.includes(value.constructor.name)) {
						if(isPartialEx) delete data[key];
						else return false;
					}
					else if(!allowDataType.includes(typeof value)) {
						if(isPartialEx) delete data[key];
						else return false;
					}
					else if(value instanceof Function) {
						if(isPartialEx) delete data[key];
						else return false;
					}
					else if(value instanceof HTMLElement) {
						if(isPartialEx) delete data[key];
						else return false;
					}
					else if(Array.isArray(value)) {
						if(!this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx)) return false; 
					}
					else if(value instanceof Object) {
						if(!this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx)) return false;
					}
					else if(Array.isArray(value)) {
						if(!this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx)) return false;
					}
					else if(value instanceof Object) {
						if(!this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx)) return false;
					}
				}
			}
			else if(!allowDataType.includes(typeof data)) {
				if(isPartialEx) delete data[key];
				else return false;
			}

			return true;
		},

		getDoSaveHistory(isPartialEx) {
			const allowDataType = this.historyAllowDataType;
			const exClass = this.historyExClass;
			const exKeys = this.historyExKeys;
			const data = _.cloneDeep(this.$data);
			let doSave = {};

			for(let [key, value] of Object.entries(data)) {
				if(exKeys.includes(key)) {
					delete data[key];
					
					continue;
				}
				else if(value && value.constructor && exClass.includes(value.constructor.name)) {
					delete data[key];
					
					continue;
				}
				else if(value instanceof Function) {
					delete data[key];

					continue;
				}
				else if(value instanceof HTMLElement) {
					delete data[key];
					
					continue;
				}
				else if(value instanceof Array) {
					if(this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx)) doSave[key] = value;
				}
				else if(value instanceof Object) {
					if(this.isDoSaveHistory(allowDataType, exKeys, exClass, value, isPartialEx)) doSave[key] = value;
				}
				else {
					doSave[key] = value;
				}
			}

			return doSave;
		},

		setHistoryState(url, data) {
			const uuid = window.uuidV4();

			if(window.history.state) window.history.replaceState({uuid, data}, document.title, url || window.location.href);
			else window.history.pushState({uuid, data}, document.title, url || window.location.href);
		},
	},
}

export default mixinHistory;