<template>
    <section class="fb__modal fb__modal__mask" aria-label="모달" aria-modal="true" @click.self="isBackgroundClose ? close() : null" role="modal" :style="!isMaskBackground ? 'background: transparent;' : ''">
		<div :class="`fb__modal__wrapper ${classes || ''}`" :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}${background ? 'background: ' + background : ''}`">
			<button class="fb__modal__close" v-if="isCloseButton" @click="close">닫기버튼</button>
			<slot></slot>
		</div>
	</section>
</template>
<script>
    export default {
        name : "modal-component",

		props: {
			classes: {
				type: String,
				default: null
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			background: {
				type: String,
				default: null
			},

			isMaskBackground: {
				type: Boolean,
				default: true
			},

			isBackgroundClose: {
				type: Boolean,
				default: true
			},

			isCloseButton: {
				type: Boolean,
				default: true
			},
		},
		
		mounted() {
			// document.body.style.width = document.body.scrollWidth + 'px';
			// document.body.classList.add("scroll-lock");

			this.$nextTick(() => {
				this.$emit("loaded",true);
			})
		},

		beforeDestroy() {
			// document.body.style.width = null;
			// document.body.classList.remove("scroll-lock");
		},
		
        methods: {
			close(e) {
				this.$emit("close-modal", {
					e
				});
			},
		},
    }
</script>
<style scoped lang="scss">
	$max-width: 100%;
	$max-height: 100%;
	$min-width: 100px;
	$min-height: 100px;

	.fb {
		&__modal {
			&__mask {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: center;
				position: fixed;
				z-index: 10;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: rgba(0, 0, 0, .5);
			}

			&__wrapper {
				overflow-x: hidden;
				overflow-y: auto;
				position: relative;
				max-width: $max-width;
				min-width: $min-width;
				max-height: $max-height;
				min-height: $min-height;
				min-height: 100px;
				margin: auto;

				&::-webkit-scrollbar {
					width: 4px;
				}
				
				&::-webkit-scrollbar-track {
					background: transparent;
				}
				
				&::-webkit-scrollbar-thumb {
					background: #e5e5e5;
					border-radius: 40px;
				}
			}

			&__close {
				position: absolute;
				top: 38px;
				right: 30px;		
				z-index: 5;
				width: 18px;
				height: 18px;
				background: url(/pc/assets/images/common/btn-modal-close.png) no-repeat 0 0;
				font-size: 0;
				cursor: pointer;
			}
		}
	}
</style>