const mixinDynamicSwiper = {
	methods: {
		/**
		 * create
		 * 
		 * swiper 생성
		 * 
		 * @param {Object} swiperOptions
		 */
		createSwiper(swiperOptions) {
			if(!swiperOptions) return;
			
			let swiperOption = null;
			let elements = null;
			let options = null;
			let children = null;
			let childrenLength = null;

			for(let i = 0, maxCnt = swiperOptions.length; i < maxCnt; i++) {
				swiperOption = swiperOptions[i];
				elements = this.getSwiperElements(swiperOption.refId);

				if(elements && elements.length) {
					for(let j = 0, maxCntJ = elements.length; j < maxCntJ; j++) {
						if(!elements[j]) continue;

						try {
							options = _.cloneDeep(swiperOption.options);
							children = elements[j].querySelectorAll(":scope > .swiper-wrapper > .swiper-slide");
							childrenLength = children && children.length ? children.length : 0;

							if(!children || (swiperOption.minimun || 1) >= childrenLength) continue;
							if(options) {
								if(options.navigation) {
									if(options.navigation.prevEl) options.navigation.prevEl = this.getSwiperElements(options.navigation.prevEl)[j];
									if(options.navigation.nextEl) options.navigation.nextEl = this.getSwiperElements(options.navigation.nextEl)[j];
								}
								if(options.pagination) options.pagination.el = this.getSwiperElements(options.pagination.el)[j];
								if(options.scrollbar) options.scrollbar.el = this.getSwiperElements(options.scrollbar.el)[j];
							}
							if(swiperOption.autoPlay && swiperOption.autoPlayMinimum < childrenLength) options.autoplay = { delay: 3000 };

							new Swiper(elements[j], options);
						}
						catch(ex) {
							console.error("create swiper error...", elements[j], ex);
						}
					}
				}
			}
		},

		/**
		 * getter
		 * 
		 * refId와 일치하는 swiper element를 찾아 반환
		 * 
		 * @param {String} refId
		 * @return {Array[Element]} swiperOptions
		 */
		getSwiperElements(refId) {
			const elements = this.$refs[refId];

			return elements instanceof Array ? elements : [elements];
		},
	},
}

export default mixinDynamicSwiper;