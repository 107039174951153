const mixinControllerFilters = {
	data() {
		return {
			fetches: {
				filterGroups: false,
			},
			requests: {
				filterGroups: {
					IL_CTGRY_ID: null,
				},
			},
			
			//
			filterPrice: { key: "pr" },
			filterBrand: { key: "b" },
			filterGroups: {},
			filterSearch: {},
			filterElements: {
				range: {
					noUiSlider: null,
					min: 0,
					max: 0
				},
			},
			applied: [],
		}
	},

	methods: {
		async requestFilters() {
			try {
				const parameters = this.requests.filterGroups;
				const responseFilters = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/comn/il/dispAttr/getAttrList",
					data: {
						IL_ATTR_TYPE: "C",
						IL_CTGRY_ID: parameters.IL_CTGRY_ID
					}
				});
				const responseBrand = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/comn/il/dispBrand/getBrandList",
					data: parameters
				});
				let filterGroups = null;
				let filters = this.makeFilters(responseFilters.rows || [], responseBrand.rows || [], responseBrand.bestRows || []);
				
				filterGroups = _.groupBy(filters, "GROUP_ID");
				if(filterGroups && filterGroups.b) filterGroups.b.push(_.groupBy(filterGroups.b, "CHAR_CODE"));

				this.appliedFilters(filters);
				this.initializationSearchFilters(filters);
				this.$set(this.filterGroups, parameters.IL_CTGRY_ID, filterGroups);
				this.fetches.filterGroups = true;
				this.$nextTick(() => {
					this.initializationFilterRange();
				});
			}
			catch(ex) {
				console.error("requestFilters exception...", ex);

				this.fetches.filterGroups = "error";
			}
		},

		// default - init
		initializationSearchFilters(filters) {
			const searchTypes = _.uniqBy(filters.filter(v => "search" == v.DIS_TYPE), "GROUP_ID");

			for(let i = 0, maxCnt = searchTypes.length; i < maxCnt; i++) {
				this.$set(this.filterSearch, searchTypes[i].GROUP_ID, null);
			}
		},

		initializationFilterRange(min, max) {
			const range = this.filterElements.range;
			const rangeElement = this.$refs["price-range"];

			if(rangeElement) {
				range.noUiSlider = window.noUiSlider.create(rangeElement, {
					start: [range.min || 0, range.max || 99999999],
					connect: true,
					range: {
						min: parseInt(min) || 0,
						max: parseInt(max) || 99999999
					},
					format: window.wNumb({
						decimals: 0,
       					thousand: ',',
						to(value) {
							return value + ',-';
						},
						from(value) {
							return Number(value.replace(',-', ''));
						}
					})
				});

				range.noUiSlider.on('update', function(values) {
					range.min = values[0];
					range.max = values[1];
				});
			}
		},

		// default - getter
		getFilterSearchTypeResult(filters) {
			const searchFilters = _.cloneDeep(filters);
			const text = this.filterSearch[searchFilters[0].GROUP_ID];

			searchFilters.splice(searchFilters.length - 1, 1);

			return text ? searchFilters.filter(v => -1 != v.NAME.indexOf(text)) : searchFilters;
		},

		// default - make
		makeFilters(filterRows, brandRows, brandBestRows) {
			let filters = [];
			let row = null;

			if(filterRows && filterRows.length) {
				for(let i = 0, maxCnt = filterRows.length; i < maxCnt; i++) {
					row = filterRows[i];

					if(!row.IL_ATTR_DETL || !row.IL_ATTR_DETL.length) continue;

					filters = filters.concat(row.IL_ATTR_DETL.map(v => {
						v.GROUP_ID = row.IL_ATTR_CD;
						v.DIS_TYPE = row.DIS_TYPE;
						v.TITLE = row.NAME;

						return v;
					}));
				}
			}

			if(brandRows && brandRows.length) {
				filters = filters.concat(brandRows.map(v => {
					const originalCharCode = (window.Hangul.disassemble(v.BRAND_NAME)[0]).toLowerCase().charCodeAt();
					let charCode = originalCharCode;
	
					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;
	
					v.ID = v.IL_BRAND_ID;
					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.NAME = v.BRAND_NAME;
					// BEST 랑 분기
					v.CONSONANT = (window.Hangul.disassemble(v.BRAND_NAME)[0]).toLowerCase();
					v.CHAR_CODE = charCode;
	
					return v;
				}));
			}

			if(brandBestRows && brandBestRows.length) {
				filters = filters.concat(brandBestRows.map(v => {
					v.ID = v.IL_BRAND_ID;
					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.NAME = v.BRAND_NAME;
					v.CONSONANT = "BEST";
					v.CHAR_CODE = 0;
	
					return v;
				}));
			}

			return filters;
		},

		// events
		appliedFilters(rows) {
			const queryString = this.getQueryString();
			let applied = null;

			let priceRange = null;
			let minMax = null;

			for(let pair of queryString.entries()) {
				if(this.filterPrice.key == pair[0]) {
					priceRange = this.filterElements.range;
					minMax = decodeURIComponent(pair[1]).split("~");

					if(!priceRange.min && !priceRange.max && minMax && minMax.length) {
						priceRange.min = minMax[0];
						priceRange.max = minMax[1];
						applied = {
							ID: pair[0],
							NAME: `${minMax[0]}~${minMax[1]}`,
							MIN: minMax[0],
							MAX: minMax[1]
						};
					}
				}
				else {
					applied = rows.find(v => v.ID == pair[0] && v.NAME == decodeURIComponent(pair[1]));
				}

				if(applied) this.applied.push(applied);
			}
		},

		applyPrice(e) {
			const range = this.filterElements.range;
			const id = this.filterPrice.key;
			const name = `${range.min}~${range.max}`;
			const applied = this.applied.find(v => v.ID == id);

			if(applied && applied.NAME == name) return;
			if(applied) this.applied.splice(this.applied.findIndex(v => v.ID == id), 1);
			
			this.applied.push({
				ID: id,
				GROUP_ID: id,
				NAME: name,
				MIN: range.min,
				MAX: range.max
			});
		},

		// events
		foldEvent(e) {
			const target = e.target;
			const targetBox = target.parentElement.parentElement;
			
			targetBox.classList.toggle("active");
		},

		// events - applied filters
		deleteAppliedItem(e, item) {
			const applied = this.applied;

			applied.splice(applied.findIndex(v => v.ID == item.ID), 1);
		},

		resetAppliedItems(e) {
			this.applied = [];
		},

		// events - search brand
		searchBrand(e, groupId) {
			const inputElement = this.$refs["brand-search-text" + groupId][0];

			this.$set(this.filterSearch, groupId, inputElement.value);
		},

		brandMoreToggle(e, refId) {
			this.$refs[refId][0].classList.toggle("brand-list--scroll");
		},

		toggleFilterMore(e, groupId) {
			const elements = this.$refs["filter-area" + groupId];

			if(!elements) return;
			if(elements.length) elements[0].classList.toggle("lnb__filter__area--open");
			else elements.classList.toggle("lnb__filter__area--open");
			
		},

		// events - noUiSlider
		modifyRange(e, type, range) {
			range.noUiSlider.set(["min" == type ? e.target.value : null, "max" == type ? e.target.value : null]);
		},
	},
}

export default mixinControllerFilters;