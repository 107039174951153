import Vue from 'vue';
import Vuex from 'vuex';

import createLogger from 'vuex/dist/logger';
import network from './network/network';

Vue.use(Vuex);

const initialization = async store => {}

export default new Vuex.Store({
  modules: {
    network: network,
  },
  plugins: process.env.NODE_ENV !== 'production' ? [
    createLogger,
    initialization
  ] : [
    initialization
  ]
})
