<template>
	<section>
		<h4 class="fb__title--hidden">댓글영역</h4>
		<!-- 댓글 total -->
		<div class="type-comments__total">
			<p>총 <em>{{comment.total}}</em>개의 댓글</p>
		</div>
		<!-- //댓글 total -->

		<!-- 작성 -->
		<div class="comment__write">
			<div class="comment__write__input">
				<div class="comment__write__textarea">
					<textarea
						maxlength="300"
						:placeholder="commentPlaceholder"
						:readonly="'Y' !== login"
						tabindex="0"
						v-model="comment.input.comment"
						@click="checkMember($event)"
					></textarea>
					<p class="comment__write__length">
						<em>{{comment.input.comment && comment.input.comment.length ? comment.input.comment.length : 0}}</em>/ 300
					</p>
				</div>
				<div class="fb__input__file-wrapper">
					<label class="fb__input__file comment__write__photo">
						<input
							class="blind"
							type="file"
							multiple
							accept=".png, .jpg, .jpeg"
							tabindex="0"
							:disabled="fileLength <= comment.input.files.length"
							@click="checkMember($event)"
							@change="importFiles('comment', $event, $event.target.files, 'image/png|image/jpeg', fileSize, fileLength - (comment.input.files ? comment.input.files.length : 0));"
						>
						<span>포토첨부</span>	
					</label>
					<div class="fb__input__file-alert" v-show="comment.input.files && comment.input.files.length >= 3" @click="fileCountError()">3개이상 첨부파일 alert</div>
				</div>
				<button class="comment__write__commit" tabindex="0" @click="submitComment($event, 'comment', comment)">등록</button>
			</div>
			
			<figure class="comment__image__list" v-if="comment.input.files">
				<span class="comment__image" v-for="(file, index) in comment.input.files" :key="index + '-comment-input-files'">
					<img :src="file.src" alt="">
					<button class="comment__write__upload--del" :disabled="disabledEvents" tabindex="0" @click="removeFile($event, 'comment', file)">삭제</button>
				</span>
			</figure>
		</div>
		<!-- //작성 -->

		<!-- 댓글 리스트 -->
		<template v-if="getCommentsCount()">
			<details class="comment" ref="eachComment" v-for="(comment, index) in comment.rows" :key="index + '-comment'">
				<summary class="comment__fold">
					<div class="comment__info">
						<span class="comment__badge" v-if="'Y' == comment.SELF_YN">ME</span>
						<span class="comment__id">{{comment.LOGIN_ID}}</span>
						<span class="comment__date">{{stringDateToDate(comment.CREATED, 'YYYY.MM.DD')}}</span>
					</div>
					<div class="comment__cont">
						<p class="comment__text" v-html="1 == comment.DEL_FLG ? '작성자에 의해 삭제된 댓글입니다.' : 2 == comment.DEL_FLG ? '관리자에 의해 삭제된 댓글입니다.' : comment.COMMENT">
							<!-- DEL_FLG 임시처리... -->
						</p>
						<div class="comment__btns"> 
							<span class="comment__btns--re active" v-if="!comment.DEL_FLG">
								<span>댓글아이콘</span>
								<em>{{comment.replies && comment.replies.length ? comment.replies.length : 0}}</em>
							</span> 
							<button class="comment__btns--del" :disabled="disabledEvents" v-if="'Y' == comment.SELF_YN && !comment.DEL_FLG" tabindex="0" @click="removeComment($event, 'comment', comment)">삭제버튼</button>
						</div>
						<div class="comment__image__list">
							<figure class="comment__image"  v-for="(file, index2) in comment.attac" :key="index + '-' + index2 + '-image'" tabindex="0" @click.prevent="clickImage($event, 'image', comment.attac, (index2 + 1))">
								<img v-lazy="imageRoot + '/' + file.REAL_ATTACHED" alt="" :key="imageRoot + '/' + file.REAL_ATTACHED">
							</figure>
						</div>
					</div> 
				</summary>

				<!-- 대댓글 영역-->
				<div class="comment__open" :class="'Y' != login ? 'nologin' : ''" v-if="!comment.DEL_FLG">
					<ul v-if="comment.replies">
						<li v-for="(reply, index2) in comment.replies" :key="index + '-' + index2 + '-reply'">
							<div class="comment__info">
								<span class="comment__badge" v-if="'Y' == reply.SELF_YN">ME</span>
								<span class="comment__id">{{reply.LOGIN_ID}}</span>
								<span class="comment__date">{{stringDateToDate(reply.CREATED, 'YYYY.MM.DD')}}</span>
							</div>
							<div class="comment__cont">
								<p class="comment__text" v-html="1 == reply.DEL_FLG ? '삭제된 댓글입니다.' : reply.COMMENT">
								</p>
								<div class="comment__btns">
									<button class="comment__btns--del" :disabled="disabledEvents" v-if="'Y' == reply.SELF_YN && !reply.DEL_FLG" tabindex="0" @click="removeComment($event, 'reply', reply)">삭제버튼</button>
								</div>
							</div>
						</li>
					</ul>
					<div class="comment__write" v-if="'Y' == login">
						<div class="comment__write__input" v-if="comment.input">
							<div class="comment__write__textarea">
								<textarea
									cols="30"
									rows="10"
									maxlength="300"
									:placeholder="commentPlaceholder"
									:readonly="'Y' !== login"
									tabindex="0"
									v-model="comment.input.comment"
									@click="checkMember($event)"
								></textarea>
								<p class="comment__write__length">
									<em>{{comment.input.comment && comment.input.comment.length ? comment.input.comment.length : 0}}</em>/ 300
								</p>
							</div>
							<button
								class="comment__write__upload--commit"
								:disabled="disabledEvents || 'Y' !== login"
								tabindex="0"
								@click="submitComment($event, 'reply', comment)"
							>등록</button>
						</div>
					</div>
				</div>
				<!-- //대댓글 영역-->
			</details> 
		</template>
		<!-- //댓글 리스트 -->
	</section>
</template>
<script>
	import mixin from "../../mixins/mixin";
	import files from "../../mixins/files";

    export default {
		name : "comments-component",
		mixins: [mixin, files],

		props: {
			/**
			 * type: {
			 *		id: String,
			 *		input: {
			 *			comment: any,
			 *			files: Array
			 *		},
			 *		rows: Array,
			 *		total: Number | String
			 *	}
			 */
			comment: {
				type: Object,
				required: true,
				validator(value) {
					const has = Object.prototype.hasOwnProperty;
					const id = value.id;
					const input = value.input;

					return id
						&& has.call(value, "input")
						&& has.call(input, "comment")
						&& input.files instanceof Array
						&& (
							null === value.rows
							|| value.rows instanceof Array
						)
						&& (
							0 === value.total
							|| "number" === typeof value.total
							|| "string" === typeof value.total
						);
				}
			},

			fileLength: {
				type: Number,
				default: 3,
			},

			fileSize: {
				type: Number,
				default: 30,
			},

			login: {
				type: String,
				required: true,
			},

			imageRoot: {
				type: String,
				required: true,
			},

			disabledEvents: {
				type: Boolean,
				default: false,
			} 
			
		},

		watch: {
			"comment.rows": {
				handler(newValue, oldValue) {
					this.initCommentInput();
				},
			},
			deep: true
		},

		computed: {
			commentPlaceholder() {
				return "Y" == this.login ? "저작권에 위배되며 타인에 대한 욕설과 비방이 담긴 댓글의 경우 삭제 될 수 있습니다." : "로그인하시면 댓글을 남길 수 있어요.";
			},
		},

		created() {
			this.initCommentInput();
		},

		methods: {
			/**
			 * init
			 * 
			 * comment object에 입력 prop 생성
			 */
			initCommentInput() {
				const rowCount = this.getCommentsCount();
				let row = null;

				if(rowCount) {
					for(let i = 0, maxCnt = rowCount; i < maxCnt; i++) {
						row = this.comment.rows[i];

						this.$set(row, "input", {
							comment: null
						});
					}
				}
			},

			/**
			 * get
			 * 
			 * 댓글 갯수조회
			 * 
			 * @return {Number} 댓글 갯수
			 */
			getCommentsCount() {
				const comment = this.comment;

				return comment.rows && comment.rows.length ? comment.rows.length : 0;
			},

			/**
			 * get
			 * 
			 * 댓글 XSS 공격 방지
			 * 
			 * @return {String} 댓글 텍스트
			 */
			getXSSFiltering(text) {
				return window.filterXSS(text, { stripIgnoreTag : true });
			},

			/**
			 * mixin - files
			 */
			async completedFileImport(id, element, file) {
				file.src = await this.readAsDataURL(file);
				this.comment.input.files.push(file);
			},

			/**
			 * mixin - files
			 */
			completedFilesImport(id, element, file) {
				element.value = "";
			},

			/**
			 * mixin - files
			 */
			fileCountError(id, element) {
				this.$emit("warn", {
					message: "최대 3개의 파일만 업로드 가능합니다."
				});
			},

			/**
			 * mixin - files
			 */
			fileSizeError(id, element, file) {
				this.$emit("size-error", {
					message: "사진은 최대 30MB까지 등록 가능합니다."
				});
			},

			fileFormatError(id, element, file) {
				const alert = this.alert;

				this.$emit("format-error", {
					message: "파일은 jpg, jpeg, png만 등록 가능합니다."
				});

			},

			/**
			 * mixin - files
			 */
			removeFile(e, id, file) {
				const files = this.comment.input.files;
				
				files.splice(files.indexOf(file), 1);
			},

			/**
			 * events
			 * 
			 * 부모에게 댓글 및 답글등록 이벤트 전파
			 */
			submitComment(e, type, comment) {
				if("Y" === this.login) {
					comment.input.comment = this.getXSSFiltering(comment.input.comment);
					comment.input.comment = comment.input.comment ? comment.input.comment.replace(/(\n|\r\n)/g, '<br>') : "";

					this.$emit("submit-comment", {
						e,
						type,
						comment
					});
				}
				else {
					this.checkMember(e);
				}
			},

			/**
			 * events
			 * 
			 * 부모에게 댓글 삭제 이벤트 전파
			 */
			removeComment(e, type, comment) {
				if("Y" === this.login) {
					this.$emit("remove-comment", {
						e,
						type,
						comment
					});
				}
				else {
					this.checkMember(e);
				}
			},

			/**
			 * events
			 * 
			 * 로그인 필요 이벤트 전파
			 */
			checkMember(e) {
				if("Y" != this.login) {
					e.preventDefault();

					this.$emit("do-login", {
						e
					});
				}
			},

			/**
			 * events
			 * 
			 * 부모에게 이미지 클릭 이벤트 전파
			 */
			clickImage(e, type, image, num) {
				this.$emit("click-image", {
					e,
					type,
					image,
					num
				});
			},
		},

		mounted(){
			console.log( "mounted====>" );
		} 
    }
</script>
<style scoped lang="scss">
	.type {
		&-comments {
			width: 1240px;
			margin: 80px auto auto auto;

			&__total {
				display: block;
				margin-bottom: 10px;
				color: #787878;
				font-size: 14px;
				text-align: left;

				em {
					color: #ff3e2f;
				}
			}

			.comment {
				&:first-of-type {
					border-top: 1px solid #e5e5e5;
				}

				&__write {					

					&__input {
						display: block;
                        width: 100%;
						height: 90px;

						&:after {
							display: block;
							clear: both;
							content: "";
						}
					}

					&__textarea {
						float: left;
						position: relative;
						width: calc(100% - 180px);
						height: 100%;
						background: #fff;
						border: 1px solid #d2d2d2;
						border-width: 1px 0 1px 1px;

						textarea {
							width: 100%;
                            height: 60px;
							padding: 16px;
							background: none;
							box-sizing: border-box;

							&::placeholder {
								color: #a4a4a4;
							}
						}
					}

					&__length {
						position: absolute;
						bottom: 12px;
						right: 16px;
						color: #787878;
						font: {
							family: 'GillSansWGL', sans-serif;
							size: 12px;
						}
					}

                    &__photo {
                        display: block;
                        float: left;
                        position: relative;
                        width: 90px;
                        height: 100%;
                        background: #999999;
                        text-align: center;

                       
                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: #fff;
                            font-weight: bold;
                            font-size: 12px;

                            &:before {
                                display: block;
                                width: 15px;
                                height: 13px;
                                margin: 0 auto 5px;
                                background: url(/pc/assets/images/common/icon-event-detail-camera.png) no-repeat 0 0;
                                content: "";
                            }

                        }
                    }

                    &__commit {
                        float: left;
                        width: 90px;
                        height: 100%;
                        background: #000;
                        border: 1px solid #000;
                        color: #fff;
                        font-weight: bold;
                        font-size: 14px;
                    }

					&__upload {
						&--del {
							position: absolute;
							top: 0;
							right: 0;
							width: 12px;
							height: 14px;
							margin-left: 10px;
							background: url(/pc/assets/images/common/btn-event-del-comment.png) no-repeat 0 top;
							font-size: 0;
						}
					}
				}

				&__fold {
					padding: 25px 10px;
					border-bottom: 1px solid #e5e5e5;
				}

				&__open {
					padding: 22px 16px;
					background: #f7f7f7;
					border-bottom: 1px solid #e5e5e5;

					&.nologin {
						padding: 0;
						border-bottom: 0;

						ul {
							padding: 22px 16px;
						}
					}

					* {
						color: #787878!important;
					}

					ul {
						& + .comment__write {
							margin-top: 25px;
						}
					}

					li {
						margin-bottom: 25px;

						&:last-of-type {
							margin-bottom: 0;
						}
					}

					.comment {
						&__write {

							&__textarea {
								width: calc(100% - 93px);

								&::placeholder {
									color: #e5e5e5!important;
									font-size: 14px;
								}
							}

							&__upload--commit {
								float: left;
								width: 90px;
								height: 100%;
								background: #000;
								border: 1px solid #000;
								color: #fff !important;
								font-weight: bold;
								font-size: 14px;
							}
						}
					}
				}

				&__info {
					&:after {
						display: block;
						clear: both;
						content: "";
					}
				}

				&__image {
					display: inline-block;
					overflow: hidden;
					position: relative;
					width: 90px;
					height: 90px;
					margin: 15px 13px 0 0;
					background: #f7f7f7;
					box-sizing: initial;

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: auto;
						max-height: none;
						transform: translate(-50%, -50%);
					}

					&__list {
						display: block;
						padding-bottom: 15px;
						&:after {
							display: block;
							clear: both;
							content: "";
						}
					}
				}

				&__badge {
					display: inline-block;
					padding: 3px 4px;
					margin-right: 8px;
					border: 1px solid #b2b2b2;
					color: rgba(0, 0, 0, 0.4);
					font-family: 'Archivo Black';
					font-size: 12px;
					line-height: 1;
				}

				&__id {
					color: #000;
					font-family: 'Archivo Black';
					font-size: 13px;
				}

				&__date {
					float: right;
					color: #787878;
					font-family: 'GillSansWGL', sans-serif;
					font-size: 13px;
				}

				&__cont {
					position: relative;
					padding: 10px 80px 0 0;
					color: #000000;
					font-size: 14px;
					line-height: 14px;

					.event__write__imgWrap {
						padding-bottom: 0;
					}
				}

				&__text {
					display: block;
					line-height: 24px;
					word-break: break-all;
				}

				&__btns {
					position: absolute;
					top: 15px;
					right: 0;
					&:after {
						display: block;
						clear: both;
						content: "";
					}

					button {
						display: inline-block;
						float: left;
					}

					.comment__btns--re {
						display: inline-block;
						float: left;
					}

					&--re {
						&:after {
							display: block;
							clear: both;
							content: "";
						}

						&.active {

							span {
								background-position: 0 0;
							}
							
							em {
								color: #000;
							}
						}
					
						span {
							float: left;
							width: 15px;
							height: 14px;
							margin-right: 7px;
							background: url(/pc/assets/images/common/btn-event-re-comment.png) no-repeat -20px 0;
							vertical-align: middle;
							font-size: 0;
						}

						em {
							float: left;
							color: #787878;
							font: {
								family: 'GillSansWGL', sans-serif;
								weight: bold;
								size: 14px;
							}
							line-height: 14px;
						}
					}

					&--del {
						width: 12px;
						height: 14px;
						margin-left: 10px;
						background: url(/pc/assets/images/common/btn-event-del-comment.png) no-repeat 0 bottom;
						font-size: 0;
						vertical-align: middle;
					}
				}
			}
		}
	}
</style>