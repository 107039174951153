<template>
    <section class="fb__modal fixed top-0 left-0 w-full h-full flex flex-no-wrap flex-col items-center z-50 1234" :class="{'noDim' : !isBackground}" @click.self="isBackgroundClose ? close() : null" style="overflow-y: initial !important" aria-label="확인" aria-modal="true">
		<div class="confirm__wrapper p-3 m-auto bg-white" :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}`">
			<div class="confirm__content text-center">
				<span v-html="message || ''"></span>
			</div>
			<footer class="text-center">
				<button type="button" class="p-2 mr-2" @click="close($event, true)">{{ok || "예"}}</button>
				<button type="button" class="p-2" @click="close($event, false)">{{cancel || "아니오"}}</button>
			</footer>
		</div>
	</section>
</template>
<script>
    export default {
        name : "confirm-component",

		props: {
			isBackgroundClose: {
				type: Boolean,
				default: false
			},

			isBackground: {
				type: Boolean,
				default: true
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			message: {
				type: String,
				default: ""
			},

			ok: {
				type: String,
				validator: prop => typeof prop === 'string' || prop === null,
				default: "예"
			},

			cancel: {
				type: String,
				default: "아니오"
			}
		},
		
		mounted() {
			// document.body.style.width = document.body.scrollWidth + "px";
			// document.body.classList.add("scroll-lock");
		},

		beforeDestroy() {
			// document.body.style.width = null;
			// document.body.classList.remove("scroll-lock");
		},
		
        methods: {
			close(e, value) {
				this.$emit("close-confirm", {
					e,
					value
				});
			},

			testscroll(e) {
				console.log('e: ', e);
			},
		},
    }
</script>
<style scoped lang="scss">
	section {
		$max-width: 960px;
		$min-width: 403px;

		background: rgba(0, 0, 0, .5);

		&.noDim {
			background: none;
		}

		.confirm__wrapper {
			max-width: $max-width;
			min-width: $min-width;
			height: auto;
			padding: 20px 0 40px;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
		}

		.confirm__content { 
			  
			min-height: 100px;
			display: flex; 
			align-items: center;
			justify-content: center;
			padding: 0 50px;
 
			span {  
				display: inline-block;   
				line-height: 22px; 
				vertical-align: middle; 
				font-size: 16px;
				padding:0 10px;
				box-sizing: border-box;
			} 

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 0;
				height: 100%;
			}
		}

		footer {
			button {
				width: auto;
				min-width: 85px;
				height: 45px;
				padding: 0;
				background: #000;
				line-height: 45px;
				font-size: 16px;
				color: #fff; 
				padding:0 10px;
			}
		}
	}
</style>