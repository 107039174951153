<template>
    <div class="fb__customer__top">
        <div class="faq">
            <header class="faq__header">
                <h4 class="faq__title">
                    <span class="fb__font__e">FAQ</span>
                </h4>
                <nav class="faq__nav" v-if="fetches.faqMenus === true">
                    <label v-for="(menu, index) in faqMenus" :key="index">
                        <input type="radio"  name="faqRadio" v-model="faqRadio" :value="menu.value" checked>
                        <span>
                            {{menu.name}}
                        </span>
                    </label>
                </nav>
            </header>
            <div class="faq__form">
                <form v-on:submit.prevent="requestFaq">
                    <fieldset >
                        <legend>검색 폼</legend>
                        <p class="faq__form__info">
                            자주찾는 질문에서 궁금하신점을 물어보세요
                        </p>
                        <div class="faq__text__wrap">
                            <input type="text" name="sText"   placeholder="검색어를 입력해주세요" v-model="faqText" title="검색어">
                            <input type="submit" value="검색" title="검색" >
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "cscenter-top-component",
        data () {
            return  {
                fetches: {
                    faqMenus: false,
                },

                faqMenus: null,
                faqText: null,
                faqRadio: "0",
            }
        },

        created() {
            this.requestFaqMenus(); 
        },

        props: {
            faq: {
                type: Object,
                P_CATEGORY_ID: 16,
                sPage: 0,
                ePage: 10,
                MAIN_YN: "",
                CS_CATEGORY_ID: "",
                CONDI_VALUE: "",
            },
            required: true
        },

        watch: {
            faqRadio: {
                handler(newValue, oldValue) {
                    this.faqText = "";
                    this.$emit('faq-radio', newValue)
                },
                deep: true
            }
        },

        methods: {
            async requestFaq () {
                this.$emit('faq-text', this.faqText)
            },
            async requestFaqMenus() {
                try {
                    const rows= [
                        {
                            name: "전체",
                            value: "0",
                        },{
                            name: "상품",
                            value: "1",
                        },{
                            name: "회원",
                            value: "2",
                        },{
                            name: "배송",
                            value: "3",
                        },{
                            name: "반품/교환/환불",
                            value: "4",
                        },{
                            name: "주문/결제",
                            value: "5",
                        },{
                            name: "기타",
                            value: "6",
                        }
                    ];

                    const row = rows;
                    if(row && row.length) this.faqMenus = row;
                    this.fetches.faqMenus = true;
                }
                catch(ex) {
                    this.fetches.faqMenus = false;
                }
            },
        },

        
    }
</script>
<style lang="scss" scoped>
    
    .fb {
        .faq {

            &__header {
				padding-top:  28px;
                border-top: 3px solid #000;

            }
            &__title {
                margin-bottom: 60px;
                color: #000000;
                font-size: 20px;
                font-weight: bold;
            }

            &__nav {

                &--active {
                    
                }

                label {
                    display: inline-block;
                    margin: 0 5px;
                    input {
                        display: none;

                        & + span {
                            display: inline-block;
                            overflow: hidden;
                            padding: 0 22px;
                            color: #787878;
                            font: {
                                size: 14px;
                            }
                            border-radius: 25px;
                            cursor: pointer;
                            line-height: 32px;
                        }

                        &:checked + span {
                            background: #000;
                            color: #fff;
                        }
                    }
                }
            }

            &__form {
                position: relative;
                margin-top: 10px;
                height: 92px;
                background: #f7f7f7;

                &__info {
                    display: inline-block;
                    margin-left: 27px;
                    color: #787878;
                    font: {
                        size: 18px;
                    }
                    line-height: 92px;
                }
            }

            &__text__wrap {
                display: inline-block;
                position: absolute;
                top: 24px;
                right: 20px;
                width: 300px;
                height: 44px;
                padding-right: 44px;
                background: #fff;

                input[type="text"] {
                    width: 100%;
                    color: #000;
                    padding: 5px 5px 5px 20px;
                    
                    font: {
                        weight: bold;
                        size: 16px;
                    }
                    text-align: left;
                    background: transparent;
                    border: 0;
                    box-sizing: border-box;
                    height: 44px;

                    &::placeholder { 
                        color: #787878 !important;
                        font: {
                            weight: normal;
                            size: 16px !important;
                        }
                        line-height: normal !important;
                    }
                }

                input[type="submit"] {
                    display: block;
                    position: absolute;
                    top: 11px;
                    right: 16px;
                    width: 21px;
                    height: 22px;
                    background: url(/pc/assets/images/customer/icon-search.png) no-repeat 0 0;
                    text-indent: -9999px;
                    border: 0;
                }
            }
            
        }
    }
</style>