<template>
    <section class="fb__grid w-full">
		<div class="flex flex-wrap" :style="getSectionStyle()">
			<div :style="getRowStyle(index)" :key="`${index}-grid-component-${uuid}`" v-for="(row, index) in rows" :id="'goods_list__' + index">
				<slot :row="row" :index="index"></slot>
			</div>
		</div>
		
	</section>
</template>
<script>
    export default {
        name : "grid-component",

		props: {
            rows: {
                type: Array | Number,
				default: () => {
					return []
				},
				required: true
			},
			
			rowNumber: {
				type: Number,
				default: 4
			},

			marginTop: {
				type: Number,
				default: 0
			},

			marginRight: {
				type: Number,
				default: 0
			},

			marginBottom: {
				type: Number,
				default: 0
			},

			marginLeft: {
				type: Number,
				default: 0
			},
        },

		data() {
			return {
				uuid: window.uuidV4(),
			}
		},

		methods: {
			getSectionStyle() {
				const styles = [];

				if(this.marginTop) styles.push(`margin-top: -${this.marginTop}px`);
				if(this.marginRight) styles.push(`margin-right: -${this.marginRight}px`);
				if(this.marginBottom) styles.push(`margin-bottom: -${this.marginBottom}px`);
				if(this.marginLeft) styles.push(`margin-left: -${this.marginLeft}px`);

				return styles.join("; ");
			},

			getRowStyle(index) {
				const styles = [];

				if(this.marginTop) styles.push(`margin-top: ${this.marginTop}px`);
				if(this.marginRight) styles.push(`margin-right: ${this.marginRight}px`);
				if(this.marginBottom) styles.push(`margin-bottom: ${this.marginBottom}px`);
				if(this.marginLeft) styles.push(`margin-left: ${this.marginLeft}px`);

				styles.push(`flex-basis: calc((100%/${this.rowNumber}) - ${(this.marginRight + this.marginLeft)}px)`);

				return styles.join("; ");
			},
		}
    }
</script>
<style scoped lang="scss">

</style>