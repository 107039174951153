import * as util from 'util' // has no default export
import { inspect } from 'util' // or directly

const eventBus = {
	created() {
		window.VueEventBus.$on("common/moveSignInPage", payload => {
			const { isRecord } = payload;
			const uuid = window.uuidV4();

			if(!window.VueEventBus) return;
			if(isRecord) {
				if(history.state) window.history.replaceState({uuid, scrollY: window.scrollY}, document.title, window.location.href);
				else window.history.pushState({uuid, scrollY: window.scrollY}, document.title, window.location.href);
			}

			payload.page = this.$options.name || null;
			window.VueEventBus.$emit("header/moveSignInPage", payload);
		});
	},
}

export default eventBus;